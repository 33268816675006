import StatusPill from "../components/datalist/StatusPill";
const collectionDataFields = [
    {
        Header: "Collection Name",
        accessor: "title",
    },
    {
        Header: "Upload Status",
        accessor: "",
        Cell: ({ row }) => {
            const { original } = row;
            return <StatusPill value={original.isExist} />
        }
    }
]


const perPages = [
    {
        "label": "50 per page",
        value: 50
    },
    {
        "label": "100 per page",
        value: 100
    },
    {
        "label": "250 per page",
        value: 250
    },
    {
        "label": "500 per page",
        value: 500
    }
];

export { collectionDataFields, perPages }
