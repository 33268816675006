const Progress = ({ bulkAutoOperationData }) => {
    console.log(bulkAutoOperationData, 'bulkAutoOperationData');
    let bulkOperationData =[
        {
            action: 'productUpload',
            percentageCompleted: bulkAutoOperationData.doneCount / bulkAutoOperationData.limit * 100
        }
    ];

    const operation = bulkOperationData[0].action === 'deleteProduct' ? 'Deleting...' : 'Uploading products';
    const percentageCompleted = bulkOperationData[0].percentageCompleted.toFixed(2);
    return (
        <>
            <div className="ml-4 w-full md:w-2/3 lg:w-1/3" style={{ minWidth: "300px" }}>
                <div className="flex items-center justify-between">
                    <span className={`text-xs font-semibold inline-block py-1 px-2 rounded-full ${bulkOperationData[0].action === 'deleteProduct' ? 'text-pink-600 bg-pink-200' : 'text-emerald-600 bg-emerald-200'}`}>
                        {operation} {`(${bulkAutoOperationData.doneCount}/${bulkAutoOperationData.limit})`}
                    </span>
                    <span className={`px-2 py-1 bg-teal-50 rounded-lg text-xs ${bulkOperationData[0].action === 'deleteProduct' ? 'text-pink-400' : 'text-teal-400'} font-medium min-w-[46px] text-center`}>{percentageCompleted}%</span>
                </div>
                <div className="w-full bg-slate-300 h-1 mb-2 mt-2">
                    <div className={`${bulkOperationData[0].action === 'deleteProduct' ? 'bg-pink-400' : 'bg-teal-400'} h-1 rounded`} style={{ width: `${percentageCompleted}%` }}></div>
                </div>
                {bulkAutoOperationData.shopify_creation_limit && <p className="text-xs text-red-800">*Shopify variant creation limit exceeded.</p>}
            </div>
        </>
    )
}

export default Progress;