import StatusPill from "../components/datalist/StatusPill";

const handleViewAction = (rowData) => {
    window.location.href = `/product/${rowData._id}`;
    console.log(rowData)
}


const productDataFields = [
    {
        Header: "Product",
        accessor: "mouser_part_number",
    },
    {
        Header: 'Images',
        accessor: 'part_data.ImagePath',
        Cell: ({ value }) => <img className="max-h-full max-w-full object-cover shadow-sm dark:border-transparent h-12 w-12 bg-gray-100 border rounded-full p-1" src={value} alt="Image" />,
    },
    {
        Header: "Shopify Product",
        accessor: "shopify_product_id",
        Cell: ({ value }) => <div>{value ? <span>{value}</span> : <span>00000000000000</span>}</div>
    },
    {
        Header: "Manufacturer",
        accessor: "part_data.Manufacturer",
    },
    {
        Header: "Stock",
        accessor: "part_data.AvailabilityInStock",
    },
    {
        Header: "Category",
        accessor: "part_data.Category",
    },
    {
        Header: "Status",
        accessor: "",
        Cell: ({ row }) => {
            const { original } = row;
            if (original.upload_status == false) {
                return <StatusPill value={original.upload_status} />
            }
            else {
                return <StatusPill value={original.shopify_data.status} />
            }
        }
    },
    {
        Header: "Actions",
        accessor: "id", // Assuming 'id' is a unique identifier for each product
        Cell: ({ cell }) => (
            <button onClick={() => handleViewAction(cell.row.original)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill='#7c3aed'><path d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z" /></svg>
            </button >
        ),
    },
]


const actionFields = [
    {
        label: "Select Action",
        value: "select_option"
    },
    // {
    //     label: "Upload Product",
    //     value: "upload_product"
    // },
    {
        label: "Delete Product From Shopify",
        value: "delete_product"
    },
    {
        label: "Delete Product From App",
        value: "delete_from_app"
    }
];

const perPages = [
    {
        "label": "50 per page",
        value: 50
    },
    {
        "label": "100 per page",
        value: 100
    },
    {
        "label": "250 per page",
        value: 250
    },
    {
        "label": "500 per page",
        value: 500
    }
];


const productFilterFields = [
    {
        labelText: "Part Number",
        labelFor: "mouser_part_number",
        id: "mouser_part_number",
        name: "mouser_part_number",
        type: "text",
        placeholder: "Enter Part Number",
    },
    {
        labelText: "Shopify Product Id",
        labelFor: "shopify_product_id",
        id: "shopify_product_id",
        name: "shopify_product_id",
        type: "text",
        autoComplete: "manufacturer",
        placeholder: "Enter Shopify Id"
    },
    {
        labelText: "Manufacturer",
        labelFor: "manufacturer",
        id: "manufacturer",
        name: "manufacturer",
        placeholder: "Manufacturer",
        type: "select",
        options: [
            { value: "all", label: "All" },
            { value: "active", label: "Active" },
            { value: "draft", label: "Draft" },
            { value: "archived", label: "Archived" },
        ],
    },
    {
        labelText: "Category",
        labelFor: "Category",
        id: "Category",
        name: "category",
        placeholder: "Category",
        type: "select",
        options: [
            { value: "all", label: "All" },
            { value: "active", label: "Active" },
            { value: "draft", label: "Draft" },
            { value: "archived", label: "Archived" },
        ],
    },
    {
        id: "status",
        name: "status",
        type: "select",
        labelText: "Status",
        labelFor: "status",
        placeholder: "Select status",
        isRequired: false,
        options: [
            { value: "all", label: "All" },
            { value: "active", label: "Active" },
            { value: 'Not Uploaded', label: "Not Uploaded" },
        ],
    },
]




export { productDataFields, productFilterFields, actionFields, perPages }
