import { React, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import useAuth from '../../hooks/auth-hook';
import Avatar from '../../assets/avatar.png';
import DigitalClock from '../../contexts/DigitalClock';
import { fetchProductData, userLogout } from '../../utils/api';
import Loader from '../../contexts/Loader';
import StatusPill from '../datalist/StatusPill';
import FullScreen from '../../contexts/FullScreen';

const Header = ({ issidebar, changeSidebar, islogin, isBreadcrumb, handleOpenBreadcrumb }) => {
  const Auth = useAuth();
  const { email, name, user_id } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const modalRef = useRef(null);
  const dropRef = useRef(null);
  const searchInputRef = useRef(null);
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [data, setData] = useState(false);
  const [error, setError] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(null);


  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const logoutbtn = async () => {
    setIsSubmitted(false);
    const userData = JSON.parse(localStorage.getItem("userData"));
    const data = { token: userData.token };
    const response = await userLogout(data);
    localStorage.clear();
    setIsSubmitted(null);
    window.location.replace('/');
  };

  const handleOutsideClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeModal();
    }
  };

  const handleOutsideDropClick = (event) => {
    if (dropRef.current && !dropRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  const handleLinkClick = () => setShowDropdown(false);

  const handleEscapeKey = (event) => {
    if (event.key === 'Escape') {
      closeModal();
    }
  };

  const handleSearchInputChange = (event) => {
    setSearchValue(event.target.value);
    setShowDropdown(true);
  };

  useEffect(() => {
    if (searchValue.length > 0) {
      const queryString = `page=1&perPage=50&query=${searchValue}`
      handleFetchData(queryString);
    }
  }, [searchValue]);


  const handleFetchData = async (queryString) => {
    try {
      const response = await fetchProductData(queryString, setData);
      console.log(response.data.products);
      setData(response.data.products);
      setError(response.error);
    } catch (error) {
      setError(error);
    }
  };

  const handleSearchFocus = () => setShowDropdown(true);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    document.addEventListener('mousedown', handleOutsideDropClick);
    document.addEventListener('keydown', handleEscapeKey);
    const searchInputActive = searchInputRef.current;
    searchInputActive.addEventListener('focus', handleSearchFocus);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.addEventListener('mousedown', handleOutsideDropClick);
      document.removeEventListener('keydown', handleEscapeKey);
      searchInputActive.removeEventListener('focus', handleSearchFocus);
    };
  }, []);



  return (
    <>
      <header className="flex-shrink-0 border-b" style={{ backgroundColor: 'rgb(63, 61, 86) !important' }}>
        <div className="flex items-center justify-between p-2 pr-4 pl-4">
          <div className="items-center space-x-3 hidden px-2 space-x-2 lg:flex xl-flex">
            <span className="p-2 text-xl font-semibold tracking-wider uppercase lg:hidden">C</span>
            <button
              className="p-2 rounded-md focus:outline-none focus:ring"
              edge="start"
              aria-label="menu"
              onClick={changeSidebar}
            >
              <svg
                className={`w-4 h-4 text-gray-600 transform transition-transform ${!issidebar ? '-rotate-180' : ''
                  }`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path className="stroke-2" d="M13 5l7 7-7 7M5 5l7 7-7 7"></path>
              </svg>
            </button>
          </div>


          <div
            className='items-center px-0 py-2 space-x-2 hidden custom_bredcrumb'
          >
            <button
              className="rounded-md focus:outline-none focus:ring"
              aria-label="menu"
              onClick={handleOpenBreadcrumb}
            >
              <svg className="text-gray-500 h-8 w-8" fill='#EC4899' viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <rect x="4" y="5" width="16" height="2"></rect>
                <rect x="4" y="11" width="16" height="2"></rect>
                <rect x="4" y="17" width="16" height="2"></rect>
              </svg>

            </button>
          </div>

          <div className="items-center hidden px-2 space-x-2 md:flex-1  md:mr-auto md:ml-5 relative">
            <span>
              <svg
                className="w-5 h-5 text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path className="stroke-2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
              </svg>
            </span>
            <input
              type="text"
              ref={searchInputRef}
              placeholder="Search Products.."
              className="px-4 py-3 rounded-md hover:bg-gray-100 lg:max-w-sm md:py-2 md:flex-1 focus:outline-none md:focus:bg-gray-100 md:focus:shadow md:focus:border"
              value={searchValue}
              onChange={handleSearchInputChange}
            />

            {showDropdown && (
              <div className="absolute left-6 top-11 z-10 mt-1 bg-gray-50 w-full max-w-sm overflow-y-auto h-80" ref={dropRef}>
                {showLoader ? (
                  <Loader setShowLoader={setShowLoader} />
                ) : data && data.length > 0 ? (
                  <ul className="py-2">
                    {data.map((data, index) => (
                      <li key={index} className="">
                        <Link
                          to={`/product/${data.mid}`}
                          className="block text-gray-800"
                          onClick={() => {
                            handleLinkClick();
                            setShowDropdown(false); // Close the dropdown after clicking the link
                          }}
                        >
                          <div className="p-3 bg-gray-50 rounded mb-1 hover:bg-gray-200" key={data.id}>
                            <div className='mb-2 space-y-5'>
                              <div className="flex items-center gap-3">
                                <div className="relative inline-flex shrink-0 items-center justify-center outline-none h-10 w-10 rounded-full bg-primary-100 dark:bg-primary-500/20 text-primary-500 shrink-0">
                                  <div className="flex h-full w-full items-center justify-center overflow-hidden text-center transition-all duration-300 rounded-full">
                                    {data.images || data.images[0] ? (<img src={data.images[0].src} className="max-h-full max-w-full object-cover shadow-sm dark:border-transparent h-10 w-10" alt="Avatar" />
                                    ) : "No Image"}

                                  </div>
                                </div>
                                <div>
                                  <h4 className="font-heading text-sm font-light leading-tight text-muted-800 dark:text-white">
                                    <span>{data.title}</span>
                                  </h4>
                                  <p className="mb-0 text-gray-500 dark:text-white/70 text-xs flex font-normal"> Variants : {data.variants.length}
                                    {data.approved == true &&
                                      <svg className="fill-primary w-4 h-4 ltr:ml-2 rtl:mr-2" xmlns="http://www.w3.org/2000/svg" width="100" height="100" enableBackground="new 0 0 100 100" viewBox="0 0 100 100">
                                        <path className="fill-blue-500" d="M88.057,45.286l-5.456-5.455c-1.295-1.295-2.356-3.854-2.356-5.689v-7.715c0-3.67-2.998-6.668-6.667-6.67h-7.718  c-1.833,0-4.395-1.063-5.69-2.357l-5.455-5.455c-2.592-2.592-6.836-2.592-9.428,0l-5.455,5.459c-1.296,1.295-3.861,2.355-5.69,2.355  h-7.715c-3.665,0-6.667,2.998-6.667,6.668v7.715c0,1.828-1.061,4.395-2.356,5.689l-5.456,5.455c-2.594,2.592-2.594,6.836,0,9.432  l5.456,5.455c1.296,1.295,2.356,3.861,2.356,5.689v7.715c0,3.666,3.002,6.668,6.667,6.668h7.715c1.833,0,4.395,1.061,5.69,2.355  l5.455,5.457c2.592,2.59,6.836,2.59,9.428,0l5.455-5.457c1.296-1.295,3.857-2.355,5.69-2.355h7.718c3.669,0,6.667-3.002,6.667-6.668  v-7.715c0-1.836,1.062-4.395,2.356-5.689l5.456-5.455C90.647,52.122,90.647,47.878,88.057,45.286z M44.709,65.001L30,50.29  l4.714-4.713l9.996,9.996l20.577-20.572L70,39.714L44.709,65.001z"></path>
                                      </svg>
                                    }
                                  </p>
                                </div>
                                <div className="ms-auto flex items-center">
                                  <StatusPill value={data.status} />
                                </div>
                              </div>
                            </div>
                          </div >
                        </Link>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="px-4 py-2 text-gray-500">No matching results found.</p>
                )}
              </div>
            )}

          </div>

          <div className="relative flex items-center space-x-3">
            <DigitalClock />
            <FullScreen />
            <div className="relative" ref={modalRef}>
              <button
                className="p-1 bg-gray-200 rounded-full focus:outline-none focus:ring"
                onClick={toggleDropdown}
              >
                <img className="object-cover w-8 h-8 rounded-full" src={Avatar} />
              </button>
              <div className="absolute right-0 p-1 bg-green-400 rounded-full bottom-3 animate-ping"></div>
              <div className="absolute right-0 p-1 bg-green-400 border border-white rounded-full bottom-3"></div>
              {isOpen && (
                <div
                  className="w-56 md:min-w-0 absolute mt-2 transform -translate-x-full bg-white shadow-lg min-w-max translate-x-[-11rem] z-10 border border-gray-200"

                >
                  <div className="flex flex-col p-4 space-y-1 font-medium border-b items-center">
                    <div className="relative mx-auto flex h-20 w-20 items-center justify-center rounded-full" role="none">
                      <img src={Avatar} className="max-w-full rounded-full object-cover shadow-sm dark:border-transparent" alt="" role="none" />
                    </div>
                    <span className="text-gray-800">{name}</span>
                    <span className="text-xs text-gray-400 break-all text-center">{email}</span>
                  </div>
                  <ul className="flex flex-col p-2 my-2 space-y-1">
                    <li>
                      <Link to="/userprofile" className="block px-2 py-1 transition rounded-md hover:bg-gray-100">Profile</Link>
                    </li>
                    {/* <li>
                      <Link to="/customersupport" className="block px-2 py-1 transition rounded-md hover:bg-gray-100">Help</Link>
                    </li> */}
                  </ul>
                  <div className="flex items-center justify-center text-blue-700">
                    <button disabled={isSubmitted === false ? true : false} className="w-full flex justify-center py-2 px-4 text-sm font-medium no-underline text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 " onClick={logoutbtn}>
                      {isSubmitted === false ? <><svg className="animate-spin h-6 w-6 mr-3 text-white" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-1.647zm4-1.647l3 1.647C11.865 17.824 13 15.042 13 12H8a7.962 7.962 0 01-.729 3.291z"
                        />
                      </svg><span>Logging out...</span></> : <>
                        <svg className="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path className="stroke-2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path>
                        </svg>
                        <span>Logout</span>
                      </>}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div >
      </header >
    </>
  );
};

export default Header;
