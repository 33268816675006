import React, { useState, useEffect } from "react";
import useAuth from '../../hooks/auth-hook';
import { settingFields } from "../../utils/settingFields";
import Input from "../../contexts/Input";
import FormAction from '../../contexts/FormAction';
import Breadcrumb from '../../contexts/Breadcrumb';
import { getStoreSetting, updateStoreSetting, enableAutoBulkOperation, getAutoBulkOperation } from '../../utils/api';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../contexts/Loader';
import { savePriceSetting, getPriceSetting } from "../../utils/api";
import Progress from "../../components/datalist/Progress";

const Setting = () => {
    const Auth = useAuth();
    const { name } = useAuth();

    const [showLoader, setShowLoader] = useState(true);
    const requiredFields = ['shop_name', 'shopify_api_key', 'shopify_secret_key', 'shopify_access_token','locations'];
    const [isFormValid, setIsFormValid] = useState(true);
    const [successMessage, setSuccessMessage] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(true);
    const [isPriceSubmitted, setIsPriceSubmitted] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState({
        shop_name: null,
        shopify_api_key: null,
        shopify_secret_key: null,
        shopify_access_token: null
    });
    const [bulkOperationData, setBulkOperationData] = useState(null);
    const [refreshBulkOperationLoading, setRefreshBulkActionLoading] = useState(false);

    useEffect(() => {
        handleFetchData();
    }, []);

    const fields = settingFields;
    const [settingField, setSettingField] = useState(Object.fromEntries(fields.map((field) => [field.name, ""])));

    const handleChange = (e) => {
        console.log(e.target.value);
        setSettingField({ ...settingField, [e.target.name]: e.target.value });
        setData({ ...data, [e.target.name]: e.target.value, selected_location: selectedLocation });
        //button enable
        const formValues = Object.values(settingField);
        const isFormValid = requiredFields.every((field) => formValues.includes(field));
        setIsFormValid(isFormValid);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        for (const field of requiredFields) {
            if (!data[field]) {
                console.log(data[field], settingField[field], data[field])
                return setError(`${field.replace('_', ' ')} field is required.`);
            }
        }
        updateData();
    }


    const updateData = async () => {
        setIsSubmitted(false);
        const response = await updateStoreSetting(data);
        if (response.status == 200) {
            toast.success("Setting Updated", {
                position: toast.POSITION.TOP_CENTER
            });
        }
        else {
            toast.error(response.error, {
                position: toast.POSITION.TOP_CENTER
            });
        }

        setIsSubmitted(true);

    };

    const handleFetchData = async (queryString) => {
        const response = await getStoreSetting(queryString);
        console.log(response);
        setData({
            shop_name: response.data?.[0]?.shop_name,
            shopify_api_key: response.data?.[0]?.shopify_api_key,
            shopify_secret_key: response.data?.[0]?.shopify_secret_key,
            shopify_access_token: response.data?.[0]?.shopify_access_token,
            locations: response.data?.[0]?.locations,
            selected_location: response.data?.[0]?.selected_location ? response.data?.[0]?.selected_location : response.data?.[0]?.locations[0][0].id
        });
        setError(response.error);
    };
    //Price Settings
    const [priceType, setPriceType] = useState('flat');
    const [priceValue, setPriceValue] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const handlePriceTypeChange = (e) => {
        setPriceType(e.target.value);
    };

    const handlePriceValueChange = (e) => {
        setPriceValue(e.target.value);
    };

    const [selectedLocation, setSelectedLocation] = useState('');

    const handleLocationChange = (e) => {
        console.log(e.target.value);
        setData({...data, selected_location: e.target.value});
        setSelectedLocation(e.target.value);
    };

    const [isEnabled, setIsEnabled] = useState(true);

    useEffect(() => {
        (async () => {
            const response = await getPriceSetting();
            // console.log(response.settings, bulkOperationResponse);
            setPriceType(response.settings.type);
            setPriceValue(response.settings.value);
            setIsEnabled(response.settings.custom_price);
        })();

    }, []);

    useEffect(() => {
        (async () => {
            const bulkOperationResponse = await getAutoBulkOperation();
            setBulkOperationData(bulkOperationResponse.data.data[0]);
        })();
    }, [refreshBulkOperationLoading])

    const handleToggleClick = async () => {
        setIsEnabled(!isEnabled);
    };
    
    const handlePriceSubmit = async (e) => {
        setIsPriceSubmitted(false);
        e.preventDefault();
        setIsLoading(true);
        const response = await savePriceSetting({ price_setting: { custom_price: isEnabled, type: priceType, value: priceValue } });
        setTimeout(() => { setIsLoading(false); }, 1000);
        toast.success(response.successMessage, {
            position: toast.POSITION.TOP_CENTER
        });
        setIsPriceSubmitted(true);
    };

    const bulkOperationRefreshHandler = () => {
        setRefreshBulkActionLoading(!refreshBulkOperationLoading);
    }

    console.log(bulkOperationData);
    const handleAutoUploading = async () => {
        console.log("Handle auto uploading...");
        if(!bulkOperationData){
            const response = await enableAutoBulkOperation({});
            if (response.status == 200) {
                toast.success("Setting Updated", {
                    position: toast.POSITION.TOP_CENTER
                });
            }
            else {
                toast.error(response.error, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        }        
    }

    return (
        <>

            <div className='border-b pb-6'>
                <div className="flex flex-col items-start justify-between pb-2 space-y-4  lg:items-center lg:space-y-0 lg:flex-row">
                    <h1 className="text-2xl font-semibold whitespace-nowrap">Settings</h1>
                </div>
                <Breadcrumb />
            </div>
            {
                showLoader ?
                    (<Loader setShowLoader={setShowLoader} />) :
                    (<>
                        <div className="grid grid-cols-12 gap-5">
                            <div className="col-span-12 lg:col-span-6 mt-8 space-y-6">
                                <div className="max-w-md">
                                    <h2 className="text-lg font-medium mb-4">Auto product uploading</h2>
                                    <p className="text-sm text-gray-800">
                                        Enable this option to upload 1000 products per day automatically. You can check the status of the uploading as well.
                                    </p>
                                </div>
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                                <div className="bg-gray-50 mt-8 space-y-6 rounded-md shadow-md border p-4 py-6 sticky top-0">
                                    <div className="flex justify-between gap-4 flex-row">
                                        <p className="text-sm text-gray-800 leading-8">Enable auto uploading of the products, to disable this option you need to connect with the developer.</p>
                                        <button disabled={bulkOperationData ? true : false} onClick={handleAutoUploading} class={`py-2 px-5 border border-transparent text-sm font-medium rounded-md text-white ${bulkOperationData ? 'bg-red-600 hover:bg-red-700' : 'bg-purple-600 hover:bg-purple-700'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500`}>
                                            {bulkOperationData ? "Disable" : "Enable"}
                                        </button>
                                    </div>
                                    {bulkOperationData && <div className="flex gap-4">
                                        <Progress bulkAutoOperationData={bulkOperationData} />
                                        <button className="" onClick={bulkOperationRefreshHandler}>
                                            <svg className="w-8 h-8" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 30 30">
                                                <path d="M 15 3 C 12.031398 3 9.3028202 4.0834384 7.2070312 5.875 A 1.0001 1.0001 0 1 0 8.5058594 7.3945312 C 10.25407 5.9000929 12.516602 5 15 5 C 20.19656 5 24.450989 8.9379267 24.951172 14 L 22 14 L 26 20 L 30 14 L 26.949219 14 C 26.437925 7.8516588 21.277839 3 15 3 z M 4 10 L 0 16 L 3.0507812 16 C 3.562075 22.148341 8.7221607 27 15 27 C 17.968602 27 20.69718 25.916562 22.792969 24.125 A 1.0001 1.0001 0 1 0 21.494141 22.605469 C 19.74593 24.099907 17.483398 25 15 25 C 9.80344 25 5.5490109 21.062074 5.0488281 16 L 8 16 L 4 10 z"></path>
                                            </svg>
                                        </button>
                                    </div>}
                                    {bulkOperationData && <div>
                                        <p className="text-sm text-gray-800 leading-8">
                                            <span className="font-semibold">Shopify api limit: </span>
                                            {bulkOperationData.shopify_creation_limit}
                                            {!bulkOperationData.shopify_creation_limit ? 
                                            <span class="px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm bg-green-100 text-green-700">Not active</span> : 
                                            <span class="px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm bg-red-100 text-red-700">Active</span>}
                                        </p>
                                        {bulkOperationData.shopify_creation_limit && <p className="text-sm text-gray-800 leading-8">
                                            <span className="font-semibold">Shopify api limit renewal: </span>
                                            {new Date(bulkOperationData.renewal_shopify_at).toUTCString()}
                                        </p>}
                                        <p className="text-sm text-gray-800 leading-8">
                                            <span className="font-semibold">Next day renew at: </span>
                                            {new Date(bulkOperationData.renewal_at).toUTCString()}
                                        </p>
                                        <p className="text-sm text-gray-800 leading-8">
                                            <span className="font-semibold">Products created so far: </span>
                                            {bulkOperationData.total_count}
                                        </p>
                                    </div>}
                                    <p className="text-sm text-gray-700 leading-8">*Note: Enable auto uploading of the products, to disable this option you need to connect with the developer.</p>
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-12 gap-5">
                            <div className="col-span-12 lg:col-span-6 mt-8 space-y-6">
                                <div className="max-w-md">
                                    <h2 className="text-lg font-medium mb-4">How to get your Shopify API credentials</h2>
                                    <ol className="list-decimal pl-6 mb-6 ">
                                        <li className="mb-2 text-gray-800">Log in to your Shopify admin panel at <a href="#" className="text-blue-500 hover:underline">https://your-store-name.myshopify.com/admin</a>.</li>
                                        <li className="mb-2 text-gray-800">Click on "Apps" from the left-hand navigation menu.</li>
                                        <li className="mb-2 text-gray-800">Click on "Manage private apps" at the bottom of the page.</li>
                                        <li className="mb-2 text-gray-800">Click on "Create a new private app" button on the top right corner of the page.</li>
                                        <li className="mb-2 text-gray-800">Enter a name for your private app in the "App name" field.</li>
                                        <li className="mb-2 text-gray-800">Enter your email address in the "Emergency developer email" field.</li>
                                        <li className="mb-2 text-gray-800">In the "Admin API" section, enable the areas of your store that you want the app to access. This will determine the permissions of the API credentials.</li>
                                        <li className="mb-2 text-gray-800">In the "Admin API" section, click on "Generate API credentials".</li>
                                        <li className="mb-2 text-gray-800">Your api_key and api_password will be generated. Make sure to save them in a secure location.</li>
                                        <li className="mb-2 text-gray-800">Scroll down to the "Authentication" section to view your api_secret key.</li>
                                        <li className="mb-2 text-gray-800">The store_name is the first part of your store's URL, before .myshopify.com. For example, if your store's URL is <code>https://example.myshopify.com</code>, your store_name is <code>example</code>.</li>
                                    </ol>
                                    <p className="text-sm text-gray-600">Note: Keep in mind that the api_key, api_password, and api_secret are sensitive credentials and should be kept confidential. Do not share them with anyone who doesn't need access to them, and make sure to store them securely.</p>
                                </div>

                            </div>
                            <div className="col-span-12 lg:col-span-6">
                                <form className="bg-gray-50 mt-8 space-y-6 rounded-md shadow-md border p-4 py-6 sticky top-0" onSubmit={handleSubmit}>
                                    <div className="-space-y-px">
                                        {fields.map((field) => (
                                            <div key={field.name}>
                                                <label
                                                    htmlFor={field.name}
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    {field.labelText}
                                                </label>
                                                
                                                <div className="mt-1">
                                                    {field.type === 'text' ? <Input
                                                        handleChange={handleChange}
                                                        value={data[field.name] || ""}
                                                        labelFor={field.labelFor}
                                                        id={field.id}
                                                        name={field.name}
                                                        type={field.type}
                                                        placeholder={field.placeholder}
                                                        className={field.type === "select" ? 
                                                        "block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 sm:text-sm" : 
                                                        "block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 sm:text-sm"}
                                                        options={[{value: "size", label: "Size"}]}
                                                    /> : <select
                                                        onChange={handleLocationChange}
                                                        id={field.id}
                                                        name={field.name}
                                                        value={selectedLocation}
                                                        className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                                                    >
                                                        {data[field.name]?.length > 0 && data[field.name][0].map((location) => (
                                                            <option value={location.id}>{location.name} - {location.id}</option>
                                                        ))}
                                                    </select>} 
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    {error &&
                                        <div className="bg-red-100 border border-red-200 text-red-700 px-2 py-2 rounded relative" role="alert">
                                            <strong className="font-bold text-sm">Holy Shit ! </strong>
                                            <span className="block sm:inline text-xs">{error}</span>
                                        </div>
                                    }
                                    <FormAction text="Save" disabled={!isSubmitted} isSubmitted={isSubmitted} />
                                </form>
                            </div>
                        </div>

                        <div className="grid grid-cols-12 gap-5">
                            <div className="col-span-12 lg:col-span-6 mt-8 space-y-6">
                                <div className="max-w-md">
                                    <h2 className="text-lg font-medium mb-4">Price Setting(Update Price by flat value or percentage)</h2>
                                    <ol className="list-decimal pl-6 mb-6 ">
                                        <li className="mb-2 text-gray-800">The flat price increase will be added to the price of all products, and the updated prices will be reflected in Shopify.</li>
                                        <li className="mb-2 text-gray-800">The price will be increased by a certain percentage. This percentage will be calculated based on the original price, added to the price, and then the updated price will be reflected in Shopify.</li>
                                        <li><strong>After making changes in the settings, remember to click the <b>"Save"</b> button.</strong></li>
                                    </ol>
                                    <p className="text-sm text-gray-600">Note: After changing the price, update the product on Shopify using the bulk upload option.</p>
                                </div>
                            </div>
                            <div className="col-span-12 lg:col-span-6">

                                <form onSubmit={handlePriceSubmit} className="bg-gray-50 mt-8 space-y-6 rounded-md shadow-md border p-4 py-6 sticky top-0">
                                    <div className="-space-y-px">
                                        <div>
                                            <label
                                                htmlFor="priceType"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Price Type
                                            </label>
                                            <div className="mt-1">
                                                <select
                                                    onChange={handlePriceTypeChange}
                                                    id="updateType"
                                                    name="updateType"
                                                    value={priceType}
                                                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                                                >
                                                    <option value="percentage">Percentage</option>
                                                    <option value="flat">Flat</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="-space-y-px">
                                        <div>
                                            <label
                                                htmlFor="fieldName"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Value
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    onChange={handlePriceValueChange}
                                                    id="priceValue"
                                                    name="priceValue"
                                                    type="number"
                                                    value={priceValue}
                                                    placeholder="Enter a value"
                                                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="-space-y-px">
                                        <label className="relative inline-flex items-center mr-5 cursor-pointer">
                                            <input
                                                type="checkbox"
                                                value=""
                                                className="sr-only peer"
                                                checked={isEnabled}
                                                onChange={handleToggleClick}
                                            />
                                            <div className={`w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 ${isEnabled ? 'peer-checked:after:translate-x-full peer-checked:after:border-white' : ''} after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 ${isEnabled ? 'peer-checked:bg-purple-600' : ''}`}></div>
                                            <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Enable/Disable (Enable if you want to apply the increased price)</span>
                                        </label>

                                    </div>

                                    <FormAction text="Save" disabled={!isPriceSubmitted} isSubmitted={isPriceSubmitted} />
                                </form>

                            </div>
                        </div>

                        <ToastContainer />

                    </>)
            }
        </>
    );


}



export default Setting;