import axios from "axios";
import { USER_API_ENDPOINTS, PRODUCT_API_ENDPOINTS, API_HEADERS, HANDLE_API_ERROR, MANUFACTURER_API_ENDPOINTS, ORDER_API_ENDPOINTS, SETTING_API_ENDPOINTS } from "./config";


// Fetch  Product data
export const fetchProductData = async (queryString) => {
  // Function starts here
  let requestHeaders = API_HEADERS();
  try {
    const response = await axios.get(
      `${PRODUCT_API_ENDPOINTS.GET_PRODUCTS}${queryString ? queryString : ""}`,
      requestHeaders
    );
    return { data: response.data, error: null };
  } catch (error) {
    const errorMessage = HANDLE_API_ERROR(error);
    return { data: null, error: errorMessage };
  }
};
// Function ends here

// upload Product data in shopify
export const uploadProductData = async (data) => {
  // Function starts here
  let requestHeaders = API_HEADERS();
  try {
    const response = await axios.post(
      `${PRODUCT_API_ENDPOINTS.CREATE_PRODUCT}`,
      { mouser_part_numbers: data },
      requestHeaders
    );
    return { status: response.status, error: null, successMessage: response.data.message };
  } catch (error) {
    const errorMessage = HANDLE_API_ERROR(error);
    return { status: null, error: errorMessage, successMessage: null }
  }
};
// Function ends here

// upload Product data in shopify
export const deleteProductData = async (data) => {
  // Function starts here
  let requestHeaders = API_HEADERS();
  try {
    const response = await axios.post(
      `${PRODUCT_API_ENDPOINTS.DELETE_PRODUCTS}`,
      { mouser_part_numbers: data },
      requestHeaders
    );
    return { status: response.status, error: null, successMessage: response.data.message };
  } catch (error) {
    const errorMessage = HANDLE_API_ERROR(error);
    return { status: null, error: errorMessage, successMessage: null }
  }
};

//Delete Product From App

export const deleteFromApp = async (data) => {
  // Function starts here
  let requestHeaders = API_HEADERS();
  try {
    const response = await axios.post(
      `${PRODUCT_API_ENDPOINTS.DELETE_FROM_APP}`,
      { mouser_part_numbers: data },
      requestHeaders
    );
    return { status: response.status, error: null, successMessage: response.data.message };
  } catch (error) {
    const errorMessage = HANDLE_API_ERROR(error);
    return { status: null, error: errorMessage, successMessage: null }
  }
};


export const savePriceSetting = async (data) => {

  let requestHeaders = API_HEADERS();
  try {
    const response = await axios.post(
      `${USER_API_ENDPOINTS.SAVE_PRICE_SETTING}`,
      data,
      requestHeaders
    );
    return { status: response.status, error: null, successMessage: response.data.message };
  } catch (error) {
    const errorMessage = HANDLE_API_ERROR(error);
    return { status: null, error: errorMessage, successMessage: null }
  }

}


export const getPriceSetting = async () => {

  let requestHeaders = API_HEADERS();
  try {
    const response = await axios.get(
      `${USER_API_ENDPOINTS.SAVE_PRICE_SETTING}`,
      requestHeaders
    );
    return { settings: response.data.settings, error: null };
  } catch (error) {
    const errorMessage = HANDLE_API_ERROR(error);
    return { data: null, error: errorMessage, code: error.code };
  }


}



//Endof Delete request
// Function ends here

// Fetch  order  data
export const fetchOrderData = async (queryString) => {
  // Function starts here
  let requestHeaders = API_HEADERS();
  try {
    const response = await axios.get(
      `${ORDER_API_ENDPOINTS.GET_ORDERS}${queryString ? queryString : ""}`,
      requestHeaders
    );
    return { data: response.data, error: null };
  } catch (error) {
    const errorMessage = HANDLE_API_ERROR(error);
    return { data: null, error: errorMessage };
  }
};
// Function ends here


// status of fullfiment  order  data
export const updateOrderfulfillment = async (queryString) => {
  // Function starts here
  let requestHeaders = API_HEADERS();
  try {
    const response = await axios.post(
      `${MANUFACTURER_API_ENDPOINTS.FULFILL_ORDER}${queryString ? queryString : ""}`,
      '',
      requestHeaders
    );
    return { data: response.data, error: null };
  } catch (error) {
    const errorMessage = HANDLE_API_ERROR(error);
    return { data: null, error: errorMessage };
  }
};
// Function ends here


// change status manufacturter  data
export const updateManufacturterStatus = async (queryString, data) => {
  // Function starts here
  let requestHeaders = API_HEADERS();
  try {
    const response = await axios.post(
      `${MANUFACTURER_API_ENDPOINTS.UPDATE_STATUS_IMPORT}${queryString ? queryString : ""}`,
      { ...data },
      requestHeaders
    );
    return { data: response.data, error: null, status: response.status };
  } catch (error) {
    const errorMessage = HANDLE_API_ERROR(error);
    return { data: null, error: errorMessage };
  }
};
// Function ends here



// Get vendors' data
export const vendorData = async (queryString) => {
  // Function starts here
  let requestHeaders = API_HEADERS();
  try {
    const response = await axios.get(
      `${MANUFACTURER_API_ENDPOINTS.GET_MANUFACTURER}${queryString ? queryString : ""}`,
      requestHeaders
    );
    return { data: response.data, error: null };
  } catch (error) {
    const errorMessage = HANDLE_API_ERROR(error);
    return { data: null, error: errorMessage };
  }
};
// Function ends here


// import IMPORT_MANUFACTURER data
export const importVendorData = async (queryString) => {
  // Function starts here
  let requestHeaders = API_HEADERS();
  try {
    const response = await axios.get(
      `${MANUFACTURER_API_ENDPOINTS.IMPORT_MANUFACTURER}${queryString ? "/" + queryString : ""}`,
      requestHeaders
    );

    return { data: response.data, error: null };
  } catch (error) {
    const errorMessage = HANDLE_API_ERROR(error);
    return { data: null, error: errorMessage };
  }
};


// create collection
export const createCollection = async () => {
  // Function starts here

  let requestHeaders = API_HEADERS();
  try {
    const response = await axios.get(
      `${PRODUCT_API_ENDPOINTS.CREATE_COLLECTION}`,
      requestHeaders
    );

    return { data: response.data, error: null };
  } catch (error) {
    const errorMessage = HANDLE_API_ERROR(error);
    return { data: null, error: errorMessage };
  }
  // let requestHeaders = API_HEADERS();
  // try {
  //   const response = await axios.get(
  //     `${PRODUCT_API_ENDPOINTS.CREATE_COLLECTION}`,
  //     requestHeaders
  //   );

  //   return { data: response.data, error: null };
  // } catch (error) {
  //   const errorMessage = HANDLE_API_ERROR(error);
  //   return { data: null, error: errorMessage };
  // }
};


// Get store settings
export const getStoreSetting = async () => {
  // Function starts here
  let requestHeaders = API_HEADERS();
  try {
    const response = await axios.get(
      `${SETTING_API_ENDPOINTS.GET_STORE_SETTING}`,
      requestHeaders
    );
    return { data: response.data.shop_details, error: null };
  } catch (error) {
    const errorMessage = HANDLE_API_ERROR(error);
    return { data: null, error: errorMessage };
  }
};
// Function ends here


// Get user information
export const getUserInfo = async () => {
  // Function starts here
  let requestHeaders = API_HEADERS();
  try {
    const response = await axios.get(
      `${USER_API_ENDPOINTS.USER_INFO}`,
      requestHeaders
    );
    delete response.data.success;
    delete response.data.message;
    return { data: response.data, error: null };
  } catch (error) {
    const errorMessage = HANDLE_API_ERROR(error);
    return { data: null, error: errorMessage, code: error.code };
  }
};
// Function ends here


// Import product CSV
export const importProductCSV = async (formData) => {
  // Function starts here
  let requestHeaders = API_HEADERS();
  let data = formData;
  try {
    const response = await axios.post(
      `${PRODUCT_API_ENDPOINTS.CSV_IMPORT}`,
      data,
      requestHeaders
    );
    return { data: response.data, error: null };
  } catch (error) {
    const errorMessage = HANDLE_API_ERROR(error);
    return { data: null, error: errorMessage };
  }
};
// Function ends here

export const enableAutoBulkOperation = async (data) => {
  let requestHeaders = API_HEADERS();

  try{
    const response = await axios.post(
      `${SETTING_API_ENDPOINTS.AUTO_BULK_ENABLE}`,
      data,
      requestHeaders
    );
    return { status: response.status, error: null, successMessage: response.data.message };
  } 
  catch (error) 
  {
    const errorMessage = HANDLE_API_ERROR(error);
    console.log(error.response.data);
    if (error.response.data.success == false) {
      return { status: null, error: error.response.data.message, successMessage: null };
    }
    return { status: null, error: errorMessage, successMessage: null }
  }
}


export const getAutoBulkOperation = async () => {

  let requestHeaders = API_HEADERS();
  try {
    const response = await axios.get(
      `${SETTING_API_ENDPOINTS.AUTO_BULK_ENABLE}`,
      requestHeaders
    );
    
    return { data: response.data, error: null };
  } catch (error) {
    const errorMessage = HANDLE_API_ERROR(error);
    return { status: null, error: errorMessage, successMessage: null }
  }
}

// Update store settings
export const updateStoreSetting = async (data) => {
  // Function starts here
  let requestHeaders = API_HEADERS();
  try {
    const response = await axios.post(
      `${SETTING_API_ENDPOINTS.GET_STORE_SETTING}`,
      data,
      requestHeaders
    );
    return { status: response.status, error: null, successMessage: response.data.message };
  } catch (error) {
    const errorMessage = HANDLE_API_ERROR(error);
    console.log(error.response.data);
    if (error.response.data.success == false) {
      return { status: null, error: error.response.data.message, successMessage: null };
    }
    return { status: null, error: errorMessage, successMessage: null }
  }
};
// Function ends here


// Function ends here

export const userLogout = async () => {
  // Function starts here
  let requestHeaders = API_HEADERS();
  try {
    const response = await axios.get(
      `${USER_API_ENDPOINTS.USER_LOGOUT}`,
      requestHeaders
    );
    return { status: response.status, error: null, successMessage: response.data.message };
  } catch (error) {
    const errorMessage = HANDLE_API_ERROR(error);
    return { status: null, error: errorMessage, successMessage: null }
  }
};



//get default value for the filter as options

export const getProductFilterOptions = async () => {

  let requestHeaders = API_HEADERS();
  try {
    const response = await axios.get(
      `${MANUFACTURER_API_ENDPOINTS.FILTER_OPTIONS}`,
      requestHeaders
    );

    // console.log(response.data.ManufacturerList);
    const ManufacturerList = response.data.ManufacturerList.map(item => {
      return { value: item, label: item }
    });
    const CategoryList = response.data.CategoryList.map(item => {
      return { value: item, label: item }
    })

    return { ManufacturerList, CategoryList };
  } catch (error) {
    const errorMessage = HANDLE_API_ERROR(error);
    return { status: null, error: errorMessage, successMessage: null }
  }

};


//get collection 

export const getCollections = async (queryString) => {

  let requestHeaders = API_HEADERS();
  try {
    const response = await axios.get(
      `${MANUFACTURER_API_ENDPOINTS.COLLECTION_URL}${queryString ? queryString : ""}`,
      requestHeaders
    );

    return { data: response.data, error: null };

    //    return { status: response.status, error: null, successMessage: response.data.message };
  } catch (error) {
    const errorMessage = HANDLE_API_ERROR(error);
    return { status: null, error: errorMessage, successMessage: null }
  }



}

//get default value for the filter as options

export const getManufacturerFilterOptions = async () => {

  let requestHeaders = API_HEADERS();
  try {
    const response = await axios.get(
      `${MANUFACTURER_API_ENDPOINTS.MANUFACTURER_FILTERS_OPTION}`,
      requestHeaders
    );

    // console.log(response.data.ManufacturerList);
    const ManufacturerList = response.data.ManufacturerList.map(item => {
      return { value: item, label: item }
    });

    return { ManufacturerList };
  } catch (error) {
    const errorMessage = HANDLE_API_ERROR(error);
    return { status: null, error: errorMessage, successMessage: null }
  }

};