import Progress from "../contexts/Progress"

export const BulkDataFields = [
    {
        Header: "Type",
        accessor: "action",
    },
    {
        Header: 'In Progress',
        accessor: 'inProgress'
    },
    {
        Header: 'Completed',
        accessor: 'completed'
    },
    {
        Header: 'Total Items',
        accessor: 'totalCount'
    },
    {
        Header: 'Completed (%)',
        accessor: 'percentageCompleted',
        Cell: ({ value }) => <Progress value={value} />
    }

]


// const actionFields = [
//     {
//         label: "Select Action",
//         value: "select_option"
//     },
//     {
//         label: "Upload Product",
//         value: "upload_product"
//     },
//     {
//         label: "Delete Product",
//         value: "delete_product"
//     }
// ];


// export default { BulkDataFields };