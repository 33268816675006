export const addProductFields = [
    {
        labelText: "Product Name",
        labelFor: "productName",
        id: "productName",
        name: "productName",
        type: "text",
        autoComplete: "productName",
        placeholder: "Product Name",
    },
    {
        labelText: "Vendor Name",
        labelFor: "vendorname",
        id: "vendorname",
        name: "vendorname",
        type: "text",
        autoComplete: "vendorname",
        placeholder: "Vendor Name",
    },
    {
        labelText: "Product Type",
        labelFor: "producttype",
        id: "producttype",
        name: "producttype",
        type: "text",
        autoComplete: "producttype",
        placeholder: "Product Type",
    },
    {
        labelText: "SKU",
        labelFor: "sku",
        id: "sku",
        name: "sku",
        type: "text",
        autoComplete: "sku",
        placeholder: "SKU",
    },
    {
        labelText: "Minimum Qty",
        labelFor: "minimumQty",
        id: "minimumQty",
        name: "minimumQty",
        type: "number",
        autoComplete: "minimumQty",
        placeholder: "Minimum Qty",
        inputMode: "numeric",
    },
    {
        labelText: "Quantity",
        labelFor: "quantity",
        id: "quantity",
        name: "quantity",
        type: "number",
        autoComplete: "quantity",
        placeholder: "Quantity",
        inputMode: "numeric",
    },
    {
        labelText: "Price",
        labelFor: "price",
        id: "price",
        labelFor: "price",
        name: "price",
        type: "number",
        step: "0.01",
        min: "0",
        autoComplete: "price",
        placeholder: "Price",
        inputMode: "numeric",
    },
    {
        id: "status",
        name: "status",
        type: "select",
        labelText: "Status",
        labelFor: "Status",
        placeholder: "Select Status",
        isRequired: true,
        options: [
            { value: "draft", label: "Draft" },
            { value: "achieved", label: "Achieved" },
            { value: "active", label: "Active" },
        ],
    },

];

export const addProductOptionsFields = [
    {
        id: "options",
        name: "options",
        type: "select",
        labelText: "options",
        labelFor: "options",
        placeholder: "Select Options",
        isRequired: true,
        options: [
            { value: "choose_option", label: "Choose option" },
            { value: "size", label: "Size" },
            { value: "color", label: "Color" },
            { value: "material", label: "Material" },
            { value: "style", label: "Style" },
        ],
    },
    {
        id: "optionsValue",
        name: "optionsValue",
        type: "text",
        labelText: "optionsValue",
        labelFor: "optionsValue",
        placeholder: "Enter Option Value",
        isRequired: true,
    },
]

export const addProductVariantsFields = [
    {
        labelText: "Variant Name",
        labelFor: "variantname",
        id: "variantname",
        name: "variantname",
        type: "text",
        autoComplete: "variantname",
        placeholder: "Variants Name",
        isReadonly: true
    },
    {
        labelText: "Price",
        labelFor: "price",
        id: "variantprice",
        labelFor: "variantprice",
        name: "variantprice",
        type: "number",
        step: "0.01",
        min: "0",
        autoComplete: "variantprice",
        placeholder: "Price",
        inputMode: "numeric",
    },
    {
        labelText: "Quantity",
        labelFor: "quantity",
        id: "variantquantity",
        name: "variantquantity",
        type: "number",
        autoComplete: "quantity",
        placeholder: "variantquantity",
        inputMode: "numeric",
    },
    {
        labelText: "SKU",
        labelFor: "sku",
        id: "variantsku",
        name: "variantsku",
        type: "text",
        autoComplete: "variantsku",
        placeholder: "SKU",
    },
]