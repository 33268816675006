import React, { useState, useEffect, useCallback } from 'react';
import { productFilterFields, productDataFields, actionFields, perPages, manufactureName } from "../../utils/productFileds";
import { fetchProductData, uploadProductData, deleteProductData, deleteFromApp } from '../../utils/api';
import Table from '../../components/datalist/Table';
import Modal from '../../contexts/Modal';
import Pagination from '../../components/datalist/Pagination';
import PerPage from '../../components/datalist/PerPage';
import Action from '../../components/datalist/Action';
import CartDrawer from '../../contexts/CartDrawer';
import CustomFilter from '../../components/datalist/CustomFilter';
import FilterBadge from '../../components/datalist/FilterBadge';
import Breadcrumb from '../../contexts/Breadcrumb';
import { ToastContainer, toast } from 'react-toastify';
import AjaxLoader from '../../contexts/AjaxLoader';
import 'react-toastify/dist/ReactToastify.css';
import BulkAction from '../../contexts/BulkAction';
import { getProductFilterOptions } from "../../utils/api";

import { bulkOperation } from "../../services/BulkOperationService";
import  '../../index.css'

const GetQuery = (query) => {
  let queryString = '';
  for (const key in query) {
    let value = (query[key].includes('&')) ? query[key].replace("&", "%26") : query[key];
    if (query[key] !== '') queryString += `&${key}=${value}`;
  }
  return queryString;
}

const ProductsList = () => {

  const [data, setData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [noSelectedItems, setNoSelectedItems] = useState(false);
  const [modalheading, setModalHeading] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);
  const [error, setError] = useState(null);
  const [size, setSize] = useState(50);
  const [count, setCount] = useState(null);
  const [selectedAction, setSelectedAction] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [showCart, setShowCart] = useState(false);
  const [search, setSearch] = useState(null);
  const [filterQuery, setFilterQuery] = useState({});

  const [ajaxLoader, setAJaxLoader] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };


  //load the data automatically
  useEffect(() => {
    let queryString = `page=${page}&perPage=${size}`;
    if (search) {
      queryString += `&query=${search}`;
    }

    if (Object.keys(queryString).length > 0) {
      queryString += GetQuery(filterQuery);
    }
    handleFetchData(queryString);
  }, [page, size, search, filterQuery]);


  const handleFetchData = async (queryString) => {
    try {
      setShowLoader(true);
      const response = await fetchProductData(queryString, setData);
      setCount(response.data.count)
      setData(response.data.data);
      setError(response.error);
    } catch (error) {
      setError(error);
    }
    finally {
      setShowLoader(false);
    }
  };

  async function addOptions() {
    const response = await getProductFilterOptions();
    console.log(response);
    productFilterFields[2].options = response.ManufacturerList;
    productFilterFields[3].options = response.CategoryList;

  }

  useEffect(() => {
    addOptions();
  }, []);



  const handlePageChange = (e) => {
    setPage(e.target.value);

  }


  const onRemove = (key) => {
    const updatedQuery = { ...filterQuery };
    delete updatedQuery[key];
    setFilterQuery(updatedQuery);
  }

  const selectAction = (e) => {
    setSelectedAction(e.target.value);
  }

  const handleAction = async (e) => {
    if (selectedIndex.length > 0) {
      setNoSelectedItems(false);
      if (selectedAction === 'upload_product') {
        handleOpenModal();
        setModalHeading('Upload Product');
      } else if (selectedAction === 'delete_product') {
        handleOpenModal();
        setModalHeading('Delete Product');
      } else if (selectedAction === 'delete_from_app') {
        handleOpenModal();
        setModalHeading('Delete Product From App');
      }
    } else {
      setNoSelectedItems(true);
    }
  };

  const handleConfirmStatus = async () => {
    if (selectedIndex.length > 0) {
      let response;

      setAJaxLoader(true);
      if (selectedAction === 'upload_product') {
        response = await uploadProductData(selectedIndex);
        if (response.status === 200) {
          toast.success(response.successMessage, {
            position: toast.POSITION.TOP_CENTER
          });
        } else {
          toast.error(response.error, {
            position: toast.POSITION.TOP_CENTER
          });
        }
        setAJaxLoader(false);
        handleCloseModal();
      } else if (selectedAction === 'delete_product') {
        response = await deleteProductData(selectedIndex);
        if (response.status === 200) {
          toast.success("Product Deleted", {
            position: toast.POSITION.TOP_CENTER
          });
        } else {
          toast.error(response.error, {
            position: toast.POSITION.TOP_CENTER
          });
        }
        setAJaxLoader(false);
        handleCloseModal();
      } else if (selectedAction === 'delete_from_app') {

        response = await deleteFromApp(selectedIndex);
        if (response.status === 200) {
          toast.success("Product Deleted", {
            position: toast.POSITION.TOP_CENTER
          });
        } else {
          toast.error(response.error, {
            position: toast.POSITION.TOP_CENTER
          });
        }
        setAJaxLoader(false);
        handleCloseModal();

      }
    }
  };


  const handleCancelStatus = () => {
    handleCloseModal();
  }

  const handleNext = () => {
    (page >= 1 && page < (Math.ceil(count / size))) ? setPage(+page + 1) : setPage(page);
  }

  const handleChangePage = (e) => {
    setSize(e.target.value);
  }

  const handlePrev = () => {
    (page >= 2) ? setPage(+page - 1) : setPage(page);
  }

  const bulkAction = async () => {
    try {

      const data = {
        action: "productCreate",
        data: selectedIndex
      }

      let responses = await bulkOperation(data);
      console.log(responses);
      if (responses.data.success) {
        toast.success(responses.data.message, {
          position: toast.POSITION.TOP_CENTER
        });
      } else {
        toast.error(responses.data.message, {
          position: toast.POSITION.TOP_CENTER
        });
        return false;
      }

    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER
      });
      return false;
    }
  }

  return (
    <>
      <div className="flex flex-col items-start justify-between pb-6 space-y-4 border-b lg:items-center lg:space-y-0 lg:flex-row">
        <h1 className="text-2xl font-semibold whitespace-nowrap">Products List</h1>
        <Breadcrumb />
      </div>

      <Table columns={productDataFields} setPage={setPage} filterFields={productFilterFields} setSize={setSize} perPages={perPages} actionFields={actionFields} setSelectedIndex={setSelectedIndex} data={data} selectRow="true" dimension={{ height: '700px', width: '' }} count={count} showLoader={showLoader} size={size}>

        <div className='flex flex-wrap items-center mt-6 flex flex-row justify-between border-b border-gray-300 mb-6 py-4'>
          <div className='flex-grow-0 flex-shrink-0 w-full max-w-4xl'>
            <div className='my-3'>
              <label for="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search Your Products ... </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                </div>
                <input
                  value={search || ""}
                  onChange={e => {
                    setSearch(e.target.value);
                  }}
                  className='min-w-[50%] block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:outline-none focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500'
                  type="search"
                  placeholder="Search products by title, SKU or tag"
                />
                {productFilterFields ? (
                  <>
                    {/* <input type="search" id="default-search" className="min-w-[50%] block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search Mockups, Logos..." /> */}
                    <button className="flex gap-2 text-white absolute right-2.5 bottom-1.5 py-2 px-5 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500" onClick={() => setShowCart(!showCart)}>
                      <span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} ><polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon></svg></span>
                      <span>Filter</span>
                    </button>

                    {showCart && <CartDrawer onClose={() => setShowCart(false)} columns={productDataFields} data={data} >
                      <CustomFilter setShowCart={setShowCart} fields={productFilterFields} filterQuery={filterQuery} setFilterQuery={setFilterQuery} setPage={setPage} />
                    </CartDrawer>}
                  </>) : ""}
              </div>
            </div>
          </div>
          <div className='flex-grow-0 flex-shrink-0 min-w-150 '>
            <div className='flex'>
              {perPages && perPages.length > 0 && <PerPage changePage={handleChangePage} perPages={perPages} />}
            </div>
          </div>
        </div>

        <div className="flex flex-row justify-between mb-6 product-up-box gap-3">
          <div className="flex items-center gap-2 flex-col">
            <div className='flex gap-4'>
              {actionFields && actionFields.length > 0 && <Action actionFields={actionFields} handleAction={handleAction} selectAction={selectAction} />}
            </div>
            <div className='text-left ml-0 mr-auto'>
              {noSelectedItems ? <span className='text-sm text-red-500'>Please select the at least on items</span> : null}
            </div>
          </div>
          <div className="flex items-start min-w-450 items-center pagination-box gap-4">
            {/* <BulkAction bulkAction={bulkAction} title="Bulk Upload" /> */}
            <Pagination page={page} size={size} handlePageChange={handlePageChange} handleNext={handleNext} count={count} handlePrev={handlePrev} />
          </div>
        </div>


        <FilterBadge filterQuery={filterQuery} onRemove={onRemove} />

      </Table>

      <Modal isOpen={modalOpen} onClose={handleCloseModal} heading={modalheading} isLoading={ajaxLoader}>
        <div className="flex flex-col items-center justify-center w-full" role="alert">
          <div className='flex flex-col items-center justify-center border-2 border-dashed border-gray-400 rounded-lg h-24 w-full bg-green-100'>
            <p className="font-bold">Sure you want to {modalheading}</p>
          </div>
          <div className="flex place-self-end ">
            <div className="mt-4">
              <button onClick={handleConfirmStatus} className="px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500" data-action="upload">Confirm</button>
            </div>
            <div className="mt-4 ml-4">
              <button onClick={handleCancelStatus} className="px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500" data-action="cancel">Cancel</button>
            </div>
          </div>
        </div>
      </Modal>
      <ToastContainer />
    </>

  );


}


export default ProductsList;