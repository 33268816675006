import React, { useEffect, useState } from "react";
import Loader from '../../contexts/Loader';
import Table from '../../components/datalist/Table';
import Pagination from '../../components/datalist/Pagination';
import PerPage from '../../components/datalist/PerPage';
import CartDrawer from '../../contexts/CartDrawer';
import CustomFilter from '../../components/datalist/CustomFilter';
import FilterBadge from '../../components/datalist/FilterBadge';
import Breadcrumb from '../../contexts/Breadcrumb';
import { importVendorData, vendorData, getManufacturerFilterOptions } from '../../utils/api';
import { manufacturerFields, manufacturerFilterFields, perPages } from "../../utils/manufacturerFields";
import { ToastContainer, toast } from 'react-toastify';


const GetQuery = (query) => {
    let queryString = '';
    for (const key in query) {
        let value = (query[key].includes('&')) ? query[key].replace("&", "%26") : query[key];
        if (query[key] !== '') queryString += `&${key}=${value}`;
    }
    return queryString;
}



const Manufacturer = () => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [error, setError] = useState(null);
    const [size, setSize] = useState(50);
    const [count, setCount] = useState(null);
    const [selectedAction, setSelectedAction] = useState("");
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [showCart, setShowCart] = useState(false);
    const [search, setSearch] = useState(null);
    const [filterQuery, setFilterQuery] = useState({});
    const [showLoader, setShowLoader] = useState(true);
    const [isSubmitted, setIsSubmitted] = useState(true);


    const handleFetchData = async (queryString) => {
        setShowLoader(true);
        try {
            const response = await vendorData(queryString, setData);
            setCount(response.data.count)
            setData(response.data.manufactures);
            setError(response.error);
        } catch (error) {
            setError(error);
        }
        finally {
            setShowLoader(false);
        }
    };

    async function getFilterOptions() {

        try {
            const { ManufacturerList } = await getManufacturerFilterOptions();
            manufacturerFilterFields[0].options = ManufacturerList;
        } catch (e) {
            console.log(e);
        }

    }

    useEffect(() => {
        getFilterOptions();
    }, []);




    useEffect(() => {
        let queryString = `page=${page}&perPage=${size}`;
        if (search) {
            queryString += `&query=${search}`;
        }

        if (Object.keys(queryString).length > 0) {
            queryString += GetQuery(filterQuery);
        }
        handleFetchData(queryString);
    }, [page, size, search, filterQuery]);

    const handleImportManufacturer = async () => {
        setIsSubmitted(false);
        try {
            const response = await importVendorData();
            console.log(response);
            toast.success(response.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            setError(response.error);
        } catch (error) {
            setError(error);
        }
        setIsSubmitted(true);
    }


    const handleNext = () => {
        (page >= 1 && page < (Math.ceil(count / size))) ? setPage(page + 1) : setPage(page);
    }

    const handleChangePage = (e) => {
        setSize(e.target.value);
    }

    const handlePrev = () => {
        (page >= 2) ? setPage(page - 1) : setPage(page);
    }

    const handlePageChange = (e) => {
        setPage(e.target.value);

    }


    const onRemove = (key) => {
        const updatedQuery = { ...filterQuery };
        delete updatedQuery[key];
        setFilterQuery(updatedQuery);
    }


    return (
        <>

            <div className="flex flex-col items-start justify-between pb-6 space-y-4 border-b lg:items-center lg:space-y-0 lg:flex-row">
                <h1 className="text-2xl font-semibold whitespace-nowrap">Manufacturer</h1>
                <div className="space-y-6 md:space-x-2 md:space-y-0">
                    <button onClick={handleImportManufacturer} className="inline-flex items-center justify-center px-4 py-1 space-x-1 bg-pink-500 text-white rounded-md shadow animate-bounce hover:bg-pink-600">
                        {isSubmitted === false ? <><svg className="animate-spin h-6 w-6 mr-3 text-white" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-1.647zm4-1.647l3 1.647C11.865 17.824 13 15.042 13 12H8a7.962 7.962 0 01-.729 3.291z"
                            />
                        </svg><span>Importing Manufacturer...</span></> : <>
                            <span>Import Manufacturer</span>
                        </>}

                    </button>
                </div>
            </div>


            <Table columns={manufacturerFields} setPage={setPage} filterFields={manufacturerFilterFields} setSize={setSize} perPages={perPages} setSelectedIndex={setSelectedIndex} data={data} count={count} selectRow="true" dimension={{ maxHeight: '700px', width: '' }} showLoader={showLoader} size={size}>

                <div className='flex flex-wrap items-center mt-6 flex flex-row justify-between border-b border-gray-300 mb-6 py-4'>
                    <div className='flex-grow-0 flex-shrink-0 w-full max-w-4xl'>
                        <div className='my-3'>
                            <label for="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search Your Products ... </label>
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                    <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                                </div>
                                <input
                                    value={search || ""}
                                    onChange={e => {
                                        setSearch(e.target.value);
                                        //onChange(e.target.value);
                                    }}
                                    className='min-w-[50%] block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:outline-none focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500'
                                    type="search"
                                    placeholder="Search products by title, SKU or tag"
                                />
                                {manufacturerFilterFields ? (
                                    <>
                                        {/* <input type="search" id="default-search" className="min-w-[50%] block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search Mockups, Logos..." /> */}
                                        <button className="flex gap-2 text-white absolute right-2.5 bottom-1.5 py-2 px-5 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500" onClick={() => setShowCart(!showCart)}>
                                            <span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} ><polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon></svg></span>
                                            <span>Filter</span>
                                        </button>

                                        {showCart && <CartDrawer onClose={() => setShowCart(false)} columns={manufacturerFields} data={data} >
                                            <CustomFilter setShowCart={setShowCart} fields={manufacturerFilterFields} filterQuery={filterQuery} setFilterQuery={setFilterQuery} setPage={setPage} />
                                        </CartDrawer>}
                                    </>) : ""}
                            </div>
                        </div>
                    </div>
                    <div className='flex-grow-0 flex-shrink-0 min-w-150 '>
                        <div className='flex'>
                            {perPages && perPages.length > 0 && <PerPage changePage={handleChangePage} perPages={perPages} />}
                        </div>
                    </div>
                </div>

                <div className="flex flex-row justify-between mb-6">
                    <div className="flex items-center gap-2 "></div>
                    <div className="flex items-start min-w-450">
                        <Pagination page={page} size={size} handlePageChange={handlePageChange} handleNext={handleNext} count={count} handlePrev={handlePrev} />
                    </div>
                </div>


                <FilterBadge filterQuery={filterQuery} onRemove={onRemove} />

            </Table>
            <ToastContainer />


        </>
    );
}

export default Manufacturer;