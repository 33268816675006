import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import { sidemenu } from "../../utils/sidemenu";
import Logo from '../../assets/logo.png';
import Favicon from '../../assets/favicon.png';
import Giftbox from '../../assets/giftbox.png';
import useAuth from '../../hooks/auth-hook';
import { checkRole } from '../../auth';
import './header.css'

const Sidebar = ({ issidebar, islogin, isBreadcrumb, handleCloseBreadcrumb }) => {
  const { logout } = useAuth();
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);

  const logoutbtn = () => {
    logout();
    localStorage.removeItem("userData");
    localStorage.removeItem("token");
    window.location.replace("/");
  };


  return (
    <aside
      className={`${issidebar ? "smallsidebar lg:w-20 xl:w-20" : "bigsidebar lg:w-64 xl:w-64"}  ${isBreadcrumb ? "mobilesidebar md-w-10 sm-w-10" : "w-0"} fixed inset-y-0 z-10 flex flex-col flex-shrink-0 max-h-screen overflow-hidden transition-all transition transform border-r shadow-lg lg:z-auto lg:static lg:shadow-none bg-slate-50`}
    >
      {/* Side-bar-header */}
      <div className="flex items-center justify-between flex-shrink-0 p-2 h-[70px]">
        <span
          className="p-2 text-xl font-semibold leading-8 tracking-wider uppercase whitespace-nowrap"
          style={issidebar ? { margin: 'auto' } : {}}
        >
          <a href="/dashboard" className="flex items-center text-lg text-gray-600 bigside-logo">
            <img src={Logo} alt="logo" className="inline-block mt-1 h-10" />

          </a>
          <a href="/dashboard" className="flex items-center text-lg text-gray-600 collap-logo">
            <img src={Favicon} alt="favicon" className="inline-block mt-1 h-10" />

          </a>
        </span>
        <button
          className="p-2 rounded-md lg:hidden close-button"
          onClick={handleCloseBreadcrumb}
        >
          <svg
            className="w-6 h-6 text-gray-600"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path className="stroke-2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
      </div>

      {/* Side-bar-links */}
      <div
        component="nav"
        aria-labelledby="nested-list-subheader"
        className="flex-1 overflow-auto hover:overflow-y-auto flex flex-col justify-between"
      >
        <ul className="menu" id="side-menu">
          {issidebar == false}
          {sidemenu &&
            sidemenu.map((menuItem, index) => {
              if (menuItem.role !== "admin" || checkRole()) {
                let menuItemType = menuItem.type;
                let menuHeading = null;

                // Check if the current menu item has the same type as the previous item
                if (
                  index > 0 &&
                  sidemenu[index - 1].type === menuItem.type &&
                  sidemenu[index - 1].role === menuItem.role
                ) {
                  menuItemType = null; // Don't show the menu item type again
                } else {
                  // If the current item type is different from the previous item type, create a new menu heading
                  menuHeading = (
                    <li
                      className="-ml-2 border-t-[1px] capitalize	menu-heading px-4 py-3.5 text-xs font-medium text-gray-500 cursor-default mt-4"
                      data-key="t-menu"
                      key={`menu-heading-${index}`}
                    >
                      {!issidebar ? <span>{menuItem.type} </span> : null}
                    </li>
                  );
                }

                // Create the menu item
                return (
                  <>
                    {menuHeading}
                    <li key={menuItem.id}>
                      <NavLink
                        className="menu-items text-gray-600 no-underline block p-3 hover:bg-opacity-20"
                        to={menuItem.path}
                      >
                        <button
                          className="flex items-center space-x-2"
                          style={issidebar ? { margin: 'auto' } : {}}
                          onClick={handleCloseBreadcrumb}
                        >
                          <span className="icon">{menuItem.icon}</span>
                          {!issidebar ? <span>{menuItem.name}</span> : null}
                        </button>
                      </NavLink>
                    </li>
                  </>
                );
              }
              return null;
            })}
        </ul>

        {/* {!issidebar && (
          <div className="sidebar-alert text-center mx-5 my-4">
            <div className="p-4 bg-primary bg-violet-500/50 rounded-md">
              <img src={Giftbox} alt="" className="block mx-auto" />
              <div className="mt-4">
                <h5 className="text-violet-600 mb-3 font-medium">Unlimited Access</h5>
                <p className="text-slate-600 text-13 dark:text-gray-50 text-sm">
                  Upgrade your plan from a Free trial, to select ‘Business Plan’.
                </p>
                <a
                  href="#!"
                  className="btn bg-violet-600 text-white border-transparent mt-6 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white hover:bg-violet-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-violet-500 mt-10"
                >
                  Upgrade Now
                </a>
              </div>
            </div>
          </div>
        )} */}
      </div>

    </aside>
  );
};

export default Sidebar;
