import axios from "axios";
import { API_HEADERS } from "../utils/config";


function setHeader() {
    let userData = localStorage.getItem('userData');
    userData = JSON.parse(userData);
    const API_AUTH_TOKEN = userData?.token;
    axios.defaults.headers['Authorization'] = `Bearer ${API_AUTH_TOKEN}`;
}



export default {
    GET: axios.get,
    POST: axios.post,
    PUT: axios.put,
    DELETE: axios.delete,
    setHeader: setHeader
};