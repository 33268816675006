const Action = ({ selectAction, actionFields, handleAction }) => {

    return (
        <>
            {/* <label for="countries" className="whitespace-nowrap block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select an option</label> */}
            <select onChange={selectAction} id="countries" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" style={{ minWidth: "200px" }}>
                {actionFields.map(item => <option value={item.value}>{item.label}</option>)}
            </select>
            <button onClick={handleAction} className="py-2 px-5 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                Apply
            </button>
        </>
    )
}

export default Action;