import StatusPill from "../components/datalist/StatusPill";
import { checkRole } from '../auth';

const handleViewAction = (rowData) => {
    window.location.href = `/order/${rowData.shopify_order_id}`;
}


const orderDataFields = [
    // {
    //     Header: "Product ID",
    //     accessor: "id",
    // },
    {
        Header: 'Order Number',
        accessor: 'shopify_data.name',
    },
    {
        Header: 'Order Id',
        accessor: 'shopify_order_id',
    },
    {
        Header: "Customer Email",
        accessor: "shopify_data.customer.email",
    },
    {
        id: "numberOfProductsColumn",
        Header: "Number of Products",
        accessor: "shopify_data.line_items",
        Cell: ({ value }) => <div style={{ minWidth: "100px" }}>{value.length}</div>,
    },
    {
        Header: "Order Price",
        accessor: "shopify_data.subtotal_price_set.shop_money",
        Cell: ({ value }) => <div>{value.amount} {value.currency_code}</div>
    },
    {
        Header: "Status",
        accessor: "shopify_data.financial_status",
        Cell: ({ value }) => <StatusPill value={value} />,
    },
    {
        Header: "Actions",
        accessor: "id", // Assuming 'id' is a unique identifier for each product
        Cell: ({ cell }) => (
            <button onClick={() => handleViewAction(cell.row.original)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill='#7c3aed'><path d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z" /></svg>
            </button >
        ),
    },
]


const actionFields = [
    {
        label: "Select Action",
        value: "select_option"
    },
    {
        label: "Upload Product",
        value: "upload_product"
    },
    {
        label: "Delete Product",
        value: "delete_product"
    }
];

const perPages = [
    {
        "label": "50 per page",
        value: 50
    },
    {
        "label": "100 per page",
        value: 100
    },
    {
        "label": "250 per page",
        value: 250
    },
    {
        "label": "500 per page",
        value: 500
    }
];

const orderFilterFields = [
    {
        labelText: "id",
        labelFor: "id",
        id: "id",
        name: "id",
        type: "text",
        autoComplete: "id",
        placeholder: "Id",
        inputMode: "numeric"
    },
    {
        labelText: "Title",
        labelFor: "title",
        id: "title",
        name: "title",
        type: "text",
        autoComplete: "title",
        placeholder: "Title"
    },
    // {
    //     labelText: "vendor",
    //     labelFor: "vendor",
    //     id: "vendor",
    //     name: "vendor",
    //     type: "text",
    //     autoComplete: "vendor",
    //     placeholder: "Vendor"
    // },
    // {
    //     id: "status",
    //     name: "status",
    //     type: "select",
    //     labelText: "Status",
    //     labelFor: "status",
    //     placeholder: "Select status",
    //     isRequired: false,
    //     options: [
    //         { value: "all", label: "All" },
    //         { value: "active", label: "Active" },
    //         { value: "draft", label: "Draft" },
    //         { value: "archived", label: "Archived" },
    //     ],
    // },
]


export { orderDataFields, orderFilterFields, actionFields, perPages }