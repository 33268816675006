import React, { useState } from 'react';
import StatusPill from './StatusPill'
import FormAction from '../../contexts/FormAction';
import Input from "../../contexts/Input";

const CustomFilter = ({ fields, fetchData, setFilterQuery, filterQuery, setShowCart, setPage }) => {

  const fieldsState = {};
  fields.forEach((field) => (fieldsState[field.id] = ''));
  const handleChange = (e) => {
    // setFilter({ ...filter, [e.target.id]: e.target.value });
    setFilterQuery({ ...filterQuery, [e.target.id]: e.target.value });

  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // Check which button was clicked
    const action = e.nativeEvent.submitter.innerHTML.toLowerCase();
    if (action.includes('reset')) {
      setFilterQuery(fieldsState);
    } else {
      setFilterQuery({ ...filterQuery });
      setPage(1);
      setShowCart(false);
    }
  };

  return (
    <div className="filter-items">
      <form className="space-y-6 p-3" onSubmit={handleSubmit}>
        <div className="flex-1 overflow-y-auto hover:overflow-y-auto">
          {fields.map((field) => (
            <>
              <div>
                <label className="block -mb-2" key={field.id}>
                  <span className="text-md font-medium text-slate-700 mb-0">{field.placeholder}</span>
                </label>
                {field.type === 'select' ? (
                  <>
                    <Input
                      key={field.id}
                      handleChange={handleChange}
                      value={filterQuery[field.name]}
                      labelText={field.labelText}
                      labelFor={field.labelFor}
                      id={field.id}
                      name={field.name}
                      type={field.type}
                      isRequired={field.isRequired}
                      placeholder={field.placeholder}
                      options={field.options}

                    />
                    {/* {field.id === 'status' && <StatusPill value={filterQuery[field.name]} />} */}

                  </>


                ) : (
                  <>
                    <Input
                      key={field.id}
                      handleChange={handleChange}
                      value={(filterQuery[field.name]) ? filterQuery[field.name] : ''}
                      labelText={field.placeholder}
                      labelFor={field.placeholder}
                      id={field.id}
                      name={field.name}
                      type={field.type}
                      isRequired={field.isRequired}
                      placeholder={field.placeholder}
                      options={field.options}
                      pattern={field.pattern}
                      inputMode={field.inputMode}
                    />
                  </>
                )}
              </div>
            </>
          ))}
        </div>

        <div className="flex gap-2 border-t absolute bottom-0 w-full p-2 md:p-4 pl-2 md:pl-4 left-0 bg-violet-500/50" role="group" aria-label="Button group">
          <FormAction text="Apply Filter" data-action="apply" />
          <FormAction text="Reset Filter" data-action="reset" />
        </div>
      </form >
    </div >
  );
};

export default CustomFilter;

