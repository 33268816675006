import { MOUSER_API } from "../utils/config";
import Http from "./Http";

//post bulk opreation request
export const bulkOperation = async (data) => {

    try {
        Http.setHeader();
        const API = MOUSER_API.blukOperation;
        const response = await Http.POST(`${API}`, data);
        return response;
    } catch (error) {

        return error.response;
    }

}

export const getOperation = async (queryString) => {


    try {
        Http.setHeader();
        const API = MOUSER_API.blukOperation;
        const response = await Http.GET(`${API}${queryString}`);
        return response;
    } catch (error) {

        return error.response;
    }

}