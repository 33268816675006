import React from "react";
import Logo from '../../assets/logo.png';
import { Link } from "react-router-dom";


const Header = () => {

  return (
    <div className="fixed top-0 hidden p-6 lg:block lg:px-12" >
      <Link to="/" className="flex items-center space-x-2 w-48">
        <img className="" src={Logo} alt="logo" />
        {/* <p className="text-xl font-semibold uppercase text-slate-700 dark:text-navy-100">
          CEDCOSS
        </p> */}
      </Link>
    </div>
  );

}

export default Header;