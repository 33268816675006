import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './pages/dashboard/Dashboard';
import ProductsList from './pages/dashboard/ProductsList';
import ProductDetail from './pages/dashboard/ProductDetail';
import Login from './pages/auth/Login';
import ResetPassword from './pages/auth/ResetPassword';
import PNF from './pages/404';
import { checkLogin, checkRole } from './auth';
import Signup from './pages/auth/Signup';
import Setting from './pages/dashboard/Setting';
import OrdersList from './pages/dashboard/OrdersList';
import ChangePassword from './pages/auth/ChangePassword';
import AddProduct from './pages/dashboard/AddProduct';
import UserProfile from './pages/dashboard/UserProfile';
import CustomerSupport from './pages/dashboard/CustomerSupport';
import OrderDetails from './pages/dashboard/OrderDetails';
import TwoStepVerification from './pages/auth/TwoStepVerification';
import Manufacturer from './pages/dashboard/Manufacturer';
import Collection from './pages/dashboard/Collection';
import BulkOperation from './pages/dashboard/BulkOperation';
const Router = () => {
  return (
    <>
      <Routes>
        {/* auth-routes */}
        <Route
          exact
          path='/'
          element={!checkLogin() ? <Login /> : <Navigate to='/dashboard' replace={true} />}
        />
        <Route
          exact
          path='/signup'
          element={!checkLogin() ? <Signup /> : <Navigate to='/' />}
        />
        <Route
          exact
          path='/resetpassword'
          element={!checkLogin() ? <ResetPassword /> : <Navigate to='/' />}
        />
        <Route
          exact
          path='/changepassword'
          element={!checkLogin() ? <ChangePassword /> : <Navigate to='/' />}
        />
        <Route path="/userverification/:userToken"
          element={!checkLogin() ? <TwoStepVerification /> : <Navigate to='/' />}
        />

        <Route
          exact
          path='/dashboard'
          element={checkLogin() ? <Dashboard /> : <Navigate to='/' />}
        />
        <Route
          exact
          path='/collection'
          element={checkLogin() ? <Collection /> : <Navigate to='/' />}
        />
        <Route
          exact
          path='/productslist'
          element={checkLogin() ? <ProductsList /> : <Navigate to='/' />}
        />

        <Route
          exact
          path='/orderslist'
          element={checkLogin() ? <OrdersList /> : <Navigate to='/' />}
        />

        <Route
          exact
          path='/setting'
          element={checkLogin() ? <Setting /> : <Navigate to='/' />}
        />
        <Route
          exact
          path='/manufacturer'
          element={checkLogin() ? <Manufacturer /> : <Navigate to='/' />}
        />
        <Route path="/product/:productId"
          element={checkLogin() ? <ProductDetail /> : <Navigate to='/' />}
        />
        <Route path="/order/:orderId"
          element={checkLogin() ? <OrderDetails /> : <Navigate to='/' />}
        />
        <Route path="/bulk-actions"
          element={checkLogin() ? <BulkOperation /> : <Navigate to='/' />}
        />

        <Route path="/userprofile"
          element={checkLogin() ? <UserProfile /> : <Navigate to='/' />}
        />
        <Route path="/customersupport"
          element={<CustomerSupport />}
        />
        <Route path='*' element={<PNF />} />
      </Routes>

    </>
  );
};

export default Router;
