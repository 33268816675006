import React from "react";
import {Link} from 'react-router-dom';
import Logo from '../../assets/logo.png';

const RightForm = (props) => {

    return(
        <main className="flex w-full flex-col items-center bg-white dark:bg-navy-700 lg:max-w-lg">
        <div className="flex w-full max-w-sm grow flex-col justify-center p-5">
          <div className="text-center">
          <img className="mx-auto  w-48 lg:hidden" src={Logo} alt="logo" />
            <div className="mt-4">
                <h2 className="text-2xl font-semibold text-slate-600 dark:text-navy-100">
                    {props.heading}
                </h2>
                <p className="text-slate-400 dark:text-navy-300 mt-2">
                {props.paragraph} {' '}
                <Link to={props.linkUrl} className="font-medium text-purple-600 hover:text-purple-500">
                    {props.linkName}
                </Link>
                </p>
            </div>
          </div>
          <div className="">
            {props.children}
          </div>
        </div>
        <div className="my-5 flex justify-center text-xs text-slate-400 dark:text-navy-300">
          <a href="#">Privacy Notice</a>
          <div className="mx-3 my-1 w-px bg-slate-200 dark:bg-navy-500"></div>
          <a href="#">Term of service</a>
        </div>
      </main>
    );

}

export default RightForm;