import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchOrderData } from '../../utils/api';
import Table from '../../components/datalist/Table';
import Action from '../../components/datalist/Action';
import Breadcrumb from '../../contexts/Breadcrumb';
import Loader from '../../contexts/Loader';
import StatusPill from '../../components/datalist/StatusPill';
import { orderDetailFields } from "../../utils/orderDetailsFileds";



const OrderDetails = () => {

    const { orderId } = useParams();
    const [data, setData] = useState({});
    const [showLoader, setShowLoader] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const queryString = `page=1&perPage=1&id=${orderId}`
        handleFetchData(queryString);
    }, [orderId]);


    const handleFetchData = async (queryString) => {
        try {
            const response = await fetchOrderData(queryString, setData);
            const preparedData = await dataPrepare(response.data.orders);
            setData(preparedData);
            setError(response.error);
        } catch (error) {
            setError(error);
        }
        finally {
            setShowLoader(false);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);

        const options = {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        };

        const formattedDate = date.toLocaleString('en-US', options);

        const day = date.getDate();
        const suffix = getDaySuffix(day);

        return formattedDate.replace(`${day}`, `${day}${suffix}`);
    };

    const getDaySuffix = (day) => {
        if (day >= 11 && day <= 13) {
            return 'th';
        }

        switch (day % 10) {
            case 1:
                return 'st';
            case 2:
                return 'nd';
            case 3:
                return 'rd';
            default:
                return 'th';
        }
    };

    const dataPrepare = async (data) => {
        const updateData = data[data.length - 1].shopify_data;
        console.log(data[data.length - 1].shopify_data)
        const formattedDate = formatDate(updateData.created_at);
        return {
            orderNumber: updateData.id || "No data",
            billingAddress: updateData.billing_address,
            shippingAddress: updateData.shipping_address,
            productItemsData: updateData.line_items,
            orderPlaceTime: formattedDate,
            total_discounts: updateData.total_discounts,
            customerDetail: updateData.customer,
            numberOfProduct: updateData.line_items.length || 0,
            customNote: updateData.note || "No data",
            orderCancel: updateData.cancel_reason || "No data",
            shippingMethod: updateData.shipping_lines[0]?.code || "No data",
            paymentMethod: updateData.payment_gateway_names[0] || "No data",
            subTotal: updateData.sub_total || 0,
            totalTax: updateData.total_tax || 0,
            total: updateData.total_price || 0,
            financialStatus: updateData.financial_status || "No data",
            rawData: updateData,
            number: updateData.name

        };
    };

    return (
        <>
            {
                showLoader ?
                    (<Loader setShowLoader={setShowLoader} />) :
                    (<>
                        <div className="flex flex-col items-start justify-between pb-6 space-y-4 border-b lg:items-center lg:space-y-0 lg:flex-row">
                            <h1 className="text-2xl font-semibold whitespace-nowrap">Order Details</h1>
                            <p>Order Name: {data.number}</p>
                        </div>
                        <div className="box mt-4 bg-gray-50 transition-shadow border rounded-lg shadow-sm hover:shadow-lg" >
                            <div className="flex p-4 border box-head" >
                                <div className="flex justify-between w-full items-center" >
                                    <div className="flex justify-start item-start space-y-2 flex-col">
                                        <h1 className="text-base font-semibold text-gray-800 dark:text-white">Order : {data.orderNumber}</h1>
                                        <h1 className="text-base font-semibold text-gray-800 dark:text-white">Order Name : {data.number}</h1>
                                        <p className="text-sm font-medium text-gray-500 dark:text-white/70 ">Order-Placement : {data.orderPlaceTime}</p></div>
                                    <div className="flex flex-col gap-4">
                                        {/* <a className="bg-purple-100 hover:bg-purple-200 dark:bg-purple-700 dark:hover:bg-purple-900 text-purple-500 rounded-lg px-4 py-1 font-sans text-sm font-medium underline-offset-4 transition-colors duration-300" href="/orderlist">Invoice </a> */}
                                        {data.cancel_reason ? <p className='rounded-lg px-4 py-1 text-red-700 bg-red-300 text-sm font-medium'>Order Cancel : {data.cancel_reason}</p> : null}
                                    </div>
                                </div>
                            </div>
                            <div className='body p-4'>
                                <div className="sm:grid grid-cols-12 gap-6 space-y-6 sm:space-y-0">
                                    <div className="col-span-12 lg:col-span-3 xxl:!col-span-3">
                                        <div className="box shadow-none border-0 mb-0">
                                            <div className="box-body p-0 space-y-2">
                                                <h5 className="text-base font-semibold text-gray-800 dark:text-white">Billing Address</h5>
                                                <p className="text-gray-500 dark:text-white/70 text-sm">
                                                    <span className="text-sm font-bold text-gray-600">Name :</span>{" "}
                                                    {data.billingAddress ? data.billingAddress.name : "No data"}
                                                </p>
                                                <p className="text-gray-500 dark:text-white/70 text-sm">
                                                    <span className="text-sm font-bold text-gray-600">Country :</span>{" "}
                                                    {data.billingAddress ? `${data.billingAddress.country}-${data.billingAddress.country_code}` : "No data"}
                                                </p>
                                                <p className="text-gray-500 dark:text-white/70 text-sm">
                                                    <span className="text-sm font-bold text-gray-600">Province :</span>{" "}
                                                    {data.billingAddress ? data.billingAddress.province : "No data"}
                                                </p>
                                                <p className="text-gray-500 dark:text-white/70 text-sm">
                                                    <span className="text-sm font-bold text-gray-600">Address 1 :</span>{" "}
                                                    {data.billingAddress ? data.billingAddress.address1 : "No data"}
                                                </p>
                                                <p className="text-gray-500 dark:text-white/70 text-sm">
                                                    <span className="text-sm font-bold text-gray-600">Address 2 :</span>{" "}
                                                    {data.billingAddress ? data.billingAddress.address2 : "No data"}
                                                </p>
                                                <p className="text-gray-500 dark:text-white/70 text-sm">
                                                    <span className="text-sm font-bold text-gray-600">Zip :</span>{" "}
                                                    {data.billingAddress ? data.billingAddress.zip : "No data"}
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-12 lg:col-span-3 xxl:!col-span-3">
                                        <div className="box shadow-none border-0 mb-0">
                                            <div className="box-body p-0 space-y-2">
                                                <h5 className="text-base font-semibold text-gray-800 dark:text-white">Shipping Address</h5>
                                                <p className="text-gray-500 dark:text-white/70 text-sm">
                                                    <span className="text-sm font-bold text-gray-600">Name :</span>{" "}
                                                    {data.shippingAddress ? data.shippingAddress.name : "No data"}
                                                </p>
                                                <p className="text-gray-500 dark:text-white/70 text-sm">
                                                    <span className="text-sm font-bold text-gray-600">Country :</span>{" "}
                                                    {data.shippingAddress ? `${data.shippingAddress.country}-${data.shippingAddress.country_code}` : "No data"}
                                                </p>
                                                <p className="text-gray-500 dark:text-white/70 text-sm">
                                                    <span className="text-sm font-bold text-gray-600">Province :</span>{" "}
                                                    {data.shippingAddress ? data.shippingAddress.province : "No data"}
                                                </p>
                                                <p className="text-gray-500 dark:text-white/70 text-sm">
                                                    <span className="text-sm font-bold text-gray-600">Address 1 :</span>{" "}
                                                    {data.shippingAddress ? data.shippingAddress.address1 : "No data"}
                                                </p>
                                                <p className="text-gray-500 dark:text-white/70 text-sm">
                                                    <span className="text-sm font-bold text-gray-600">Address 2 :</span>{" "}
                                                    {data.shippingAddress ? data.shippingAddress.address2 : "No data"}
                                                </p>
                                                <p className="text-gray-500 dark:text-white/70 text-sm">
                                                    <span className="text-sm font-bold text-gray-600">Zip :</span>{" "}
                                                    {data.shippingAddress ? data.shippingAddress.zip : "No data"}
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-12 lg:col-span-3 xxl:!col-span-3">
                                        <div className="box shadow-none border-0 mb-0">
                                            <div className="box-body p-0 space-y-2">
                                                <h5 className="text-base font-semibold text-gray-800 dark:text-white">Shipping Method</h5>
                                                <p className="text-gray-500 dark:text-white/70 text-sm">{data.shippingMethod ? data.shippingMethod : "No data"}</p>                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-12 lg:col-span-3 xxl:!col-span-3">
                                        <div className="box shadow-none border-0 mb-0">
                                            <div className="box-body p-0 space-y-2">
                                                <h5 className="text-base font-semibold text-gray-800 dark:text-white">Payment Method</h5>
                                                <div className="flex flex-col">
                                                    <div className="flex w-full flex-col rounded-sm text-base font-semibold focus:ring-primary dark:bg-bgdark dark:text-white/70">
                                                        <p className="text-gray-500 dark:text-white/70 text-sm"><span className="text-sm font-bold text-gray-600">Card :</span>
                                                            {data.paymentMethod ? data.paymentMethod : "No Data"}
                                                        </p>
                                                        <p className="text-gray-500 dark:text-white/70 text-sm"><span className="text-sm font-bold text-gray-600">Card ending with :</span>
                                                            1253
                                                        </p>
                                                        {data.financialStatus ? <div className="my-3">Status: <StatusPill value={data.financialStatus} /></div> : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-12 box-body pt-0">
                                        {(data.productItemsData && data.productItemsData.length > 0) ? (
                                            <Table columns={orderDetailFields} data={data.productItemsData} dimension={{ maxHeight: '300px', width: '' }} count="50" size="50" />
                                        ) : (
                                            <p>No data available</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='grid grid-cols-12 gap-6'>
                            <div className="col-span-12 xxl:!col-span-5 md:col-span-6 box mt-4 bg-gray-50 transition-shadow border rounded-lg shadow-sm hover:shadow-lg" >
                                <div className="flex p-4 border box-head">
                                    <div className="flex justify-between w-full items-center">
                                        <div className="flex justify-start item-start space-y-2 flex-col">
                                            <h1 className="text-base font-semibold text-gray-800 dark:text-white">Paymeny Summary</h1>
                                        </div>
                                    </div>
                                </div>
                                <div className="box-body p-4" >
                                    <ul className="flex flex-col border">
                                        <li className="p-2 border bg-white text-gray-800 dark:bg-bgdark dark:border-white/10 dark:text-white">
                                            <div className="flex justify-between w-full"> Total Items <span className="">{data.numberOfProduct ? data.numberOfProduct : 0}</span>
                                            </div>
                                        </li>
                                        <li className="p-2 border bg-white text-gray-800 dark:bg-bgdark dark:border-white/10 dark:text-white">
                                            <div className="flex justify-between w-full"> SubTotal <span className="">{data.subTotal ? data.subTotal : 0}</span>
                                            </div>
                                        </li>
                                        <li className="p-2 border bg-white text-gray-800 dark:bg-bgdark dark:border-white/10 dark:text-white">
                                            <div className="flex justify-between w-full"> Tax Charges <span className="">{data.totalTax ? data.totalTax : 0}</span>
                                            </div>
                                        </li>
                                        <li className="p-2 border bg-white text-gray-800 dark:bg-bgdark dark:border-white/10 dark:text-white">
                                            <div className="flex justify-between w-full"> Shipping Charges <span className="text-green-500 text-end">{data?.rawData.total_shipping_price_set.shop_money.amount}</span>
                                            </div>
                                        </li>
                                        <li className="p-2 border bg-white text-gray-800 dark:bg-bgdark dark:border-white/10 dark:text-white">
                                            <div className="flex justify-between w-full"> Coupon Discount <span className="text-green-500">{data.total_discounts}</span>
                                            </div>
                                        </li>
                                        <li className="px-2 py-3 border bg-white text-gray-800 dark:bg-bgdark dark:border-white/10 dark:text-white">
                                            <div className="flex justify-between w-full text-xl font-semibold"> Total <span className="">{data.total}</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                {/* <div className="box-footer border p-4">
                                    <a href="#" className="inline-flex items-center justify-center px-4 py-2 space-x-1 bg-pink-500 text-white w-full flex shadow hover:bg-pink-600">Cancel Order</a>
                                </div> */}
                            </div>
                            <div className="col-span-12 xxl:!col-span-5 md:col-span-6 box mt-4 " >
                                <div className='box bg-gray-50 transition-shadow border rounded-lg shadow-sm hover:shadow-lg'>
                                    <div className="flex p-4 border box-head">
                                        <div className="flex justify-between w-full items-center">
                                            <div className="flex justify-start item-start space-y-2 flex-col">
                                                <h1 className="text-base font-semibold text-gray-800 dark:text-white"> Customer Details </h1>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="box-body">
                                        <div className="flex align-items-center space-x-3 rtl:space-x-reverse">
                                            <div className="flex-auto my-auto space-y-2 p-4">
                                                <div className="space-x-3">
                                                    <span className="text-sm font-bold text-gray-600">Name :</span>
                                                    <span className="text-sm text-gray-500 dark:text-white/70">{data.customerDetail && data.customerDetail.first_name ? data.customerDetail.first_name : ""} {data.customerDetail && data.customerDetail.last_name ? data.customerDetail.last_name : ""}</span>
                                                </div>
                                                <div className="space-x-3">
                                                    <span className="text-sm font-bold text-gray-600">Email :</span>
                                                    <span className="text-sm text-gray-500 dark:text-white/70">{data.customerDetail && data.customerDetail.email ? data.customerDetail.email : "No email"}</span>
                                                </div>
                                                <div className="space-x-3">
                                                    <span className="text-sm font-bold text-gray-600">Phone No :</span>
                                                    <span className="text-sm text-gray-500 dark:text-white/70">{data.customerDetail && data.customerDetail.phone ? data.customerDetail.phone : "No Phone"}</span>
                                                </div>
                                                <div className="space-x-3">
                                                    <span className="text-sm font-bold text-gray-600">Orders:</span>
                                                    <span className="text-sm text-gray-500 dark:text-white/70">{data.customerDetail && data.numberOfProduct ? data.numberOfProduct : 0}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4 box bg-gray-50 transition-shadow border rounded-lg shadow-sm hover:shadow-lg">
                                    <div className="flex p-4 border box-head">
                                        <div className="flex justify-between w-full items-center">
                                            <div className="flex justify-start item-start space-y-2 flex-col">
                                                <h1 className="text-base font-semibold text-gray-800 dark:text-white">Note</h1>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-4 box-body space-y-2">
                                        <div className="box-body p-0 space-y-2">
                                            <p className="text-gray-500 dark:text-white/70 text-sm">Note By Customer</p>
                                            <p className="text-gray-700 dark:text-white/70 text-sm">{data.customNote ? data.customNote : "No Note"}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>)
            }


        </>
    );
}

export default OrderDetails;