const API_BASE_URL = 'https://api.sicomponent.com/api';//'http://localhost:5000/api';//https://api.sicomponent.com/api

export const USER_API_ENDPOINTS = {
  LOGIN: `${API_BASE_URL}/user/login`,
  GET_USERS: `${API_BASE_URL}/users`,
  CREATE_USER: `${API_BASE_URL}/user/signup`,
  UPDATE_USER: (id) => `${API_BASE_URL}/users/${id}`,
  RESET_PASSWORD: `${API_BASE_URL}/user/password/reset`,
  CHANGE_PASSWORD: `${API_BASE_URL}/user/change-password`,
  USER_INFO: `${API_BASE_URL}/user/info`,
  USER_LOGOUT: `${API_BASE_URL}/user/logout`,
  USER_OTP_VERIFY: `${API_BASE_URL}/user/otp/verify`,
  USER_OTP_RESEND: `${API_BASE_URL}/user/otp/resend`,
  SAVE_PRICE_SETTING: `${API_BASE_URL}/user/save-price-settings`,
};

export const PRODUCT_API_ENDPOINTS = {
  // GET_PRODUCTS: `${API_BASE_URL}/products?perPage=100&page=1`,
  GET_PRODUCTS: `${API_BASE_URL}/get-parts?`,
  GET_PRODUCT_DETAILS: `${API_BASE_URL}/products/`,
  CREATE_PRODUCT: `${API_BASE_URL}/products/`,
  DELETE_PRODUCTS: `${API_BASE_URL}/products/bulk-delete`,
  DELETE_FROM_APP: `${API_BASE_URL}/products/delete-items`,
  CSV_IMPORT: `${API_BASE_URL}/products/import/csv`,
  UPDATE_PRODUCT: '',
  CREATE_COLLECTION: `${API_BASE_URL}/collections/create`
}

export const ORDER_API_ENDPOINTS = {
  GET_ORDERS: `${API_BASE_URL}/orders?`,
  FULFILL_ORDER: `${API_BASE_URL}/orders/`,

}

export const SETTING_API_ENDPOINTS = {
  GET_STORE_SETTING: `${API_BASE_URL}/setting`,
  AUTO_BULK_ENABLE: `${API_BASE_URL}/bulk/product/upload`
}

export const MANUFACTURER_API_ENDPOINTS = {
  GET_MANUFACTURER: `${API_BASE_URL}/manufacturer?`,
  IMPORT_MANUFACTURER: `${API_BASE_URL}/import-manufacturer`,
  UPDATE_STATUS_IMPORT: `${API_BASE_URL}/import-mouser-parts`,
  FILTER_OPTIONS: `${API_BASE_URL}/parts/filterOptions`,
  COLLECTION_URL: `${API_BASE_URL}/collections`,
  MANUFACTURER_FILTERS_OPTION: `${API_BASE_URL}/manufacturer/filterOpions`
}

export const MOUSER_API = {
  blukOperation: `${API_BASE_URL}/bulkOperation`
}



export const API_HEADERS = () => {
  let userData = localStorage.getItem('userData');
  userData = JSON.parse(userData);
  const API_AUTH_TOKEN = userData?.token;
  return {
    headers: {
      "Authorization": `Bearer ${API_AUTH_TOKEN}`
    }
  };
};





// error-handling

export const HANDLE_API_ERROR = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    switch (error.response.status) {
      case 204:
        return 'No content input';
      case 400:
        return 'Bad Request';
      case 401:
        return error.response.data.message;
      case 402:
        return 'Payment Required';
      case 403:
        return error.response.data.message;
      case 404:
        return error.response.data.message;
      case 405:
        return 'Method Not Allowed';
      case 406:
        return 'Not Acceptable';
      case 408:
        return 'Request Timeout';
      case 409:
        return error.response.data.message;
      case 411:
        return 'Length Required';
      case 413:
        return 'Payload Too Large';
      case 414:
        return 'URI Too Long';
      case 415:
        return 'Unsupported Media Type';
      case 416:
        return 'Range Not Satisfiable';
      case 404:
        return error.response.data.message;
      case 422:
        return error.response.data.message;
      case 423:
        return error.response.data.message;
      case 498:
        return 'User credentials expire please login again';
      case 500:
        return 'Internal Server Error';
      case 503:
        return 'Data Fetch Problem';
      default:
        return `Unknown Error (${error.response.status})`;
    }
    // You can handle the error here and display an error message to the user
  } else if (error.request) {
    // The request was made but no response was received
    return 'Something went wrong';

  } else {
    // Something happened in setting up the request that triggered an Error
    return `API Error Message: ${error.message}`;
    // You can handle the error here and display an error message to the user
  }
};