const Progress = ({ value }) => {
    return (
        <>
            <div className="w-10 h-10 rounded-full flex items-center justify-center border-2 border-purple-500 bg-gradient-to-br from-purple-400 to-blue-500 relative">
                <span className="text-white text-1xl z-10">{value}%</span>
            </div>

        </>
    )
}

export default Progress;