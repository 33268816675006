import StatusPill from "../components/datalist/StatusPill";
import { useState, useEffect } from "react";
import { updateManufacturterStatus } from '../utils/api';
const handleViewAction = (rowData) => {
    // window.location.href = `/order/${rowData.shopify_order_id}`;
    console.log("show the products");
}
const manufacturerFields = [
    {
        Header: "Manufacturer",
        accessor: "manufacturer",
    },
    {
        Header: "Imported Products",
        accessor: "imported_products",
        Cell: ({ value }) => <div>{value ? <span>{value}</span> : <span>0</span>}</div>
    },
    {
        Header: "Total Products",
        accessor: "total_products",
        Cell: ({ value }) => <div>{value ? <span>{value}</span> : <span>0</span>}</div>
    },
    // {
    //     Header : "Auto Import",
    //     accessor: "autoImport",
    //     Cell: ({ value }) => <StatusPill value={`autoimport${value}`} />,
    // },
    {
        Header: "Status",
        accessor: "fulfillment_status",
        Cell: ({ row }) => {
            const { original } = row;
            const [data, setData] = useState([]);
            const [statusState, setStatusState] = useState({
                isUpdating: false,
                updateMessage: "",
                isSuccess: false,
                isError: false,
            });
            const handleUpdateStatus = async () => {
                setStatusState((prevState) => ({ ...prevState, isUpdating: true }));

                // Perform the status update logic here, for example, call an API
                // Simulating an asynchronous API call with a setTimeout
                let queryString = ``
                let data = {
                    keyword: original.manufacturer
                }
                try {
                    // Simulating API call
                    const response = await updateManufacturterStatus(queryString, data, setData);
                    console.log(response);
                    if (response.status) {
                        setTimeout(() => {
                            setStatusState((prevState) => ({
                                ...prevState,
                                isSuccess: true,
                                isUpdating: false,
                                updateMessage: "Updated",
                            }));
                        }, 2000);
                    }
                    else {
                        setStatusState((prevState) => ({
                            ...prevState,
                            isError: true,
                            isUpdating: false,
                            updateMessage: "Failed",
                        }));
                    }
                } catch (error) {
                    setStatusState((prevState) => ({
                        ...prevState,
                        isError: true,
                        isUpdating: false,
                        updateMessage: "Failed",
                    }));
                }
            };

            return (
                <div>
                    {original && original.autoImport === false ? (
                        <button
                            onClick={handleUpdateStatus}
                            style={{ minWidth: "120px" }}
                            className={`capitalize flex items-center justify-center px-4 py-2 border border-transparent text-xs font-medium rounded-lg text-white ${statusState.isSuccess ? "bg-green-500" : statusState.isError ? "bg-red-500" : "bg-purple-600 hover:bg-purple-700"
                                }`}
                            disabled={statusState.isUpdating}
                        >
                            {statusState.isUpdating ? (
                                <svg className="animate-spin h-3 w-3 mr-3 text-white" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-1.647zm4-1.647l3 1.647C11.865 17.824 13 15.042 13 12H8a7.962 7.962 0 01-.729 3.291z"
                                    />
                                </svg>
                            ) : statusState.isSuccess ? (
                                <svg className="h-3 w-3 mr-2" viewBox="0 0 20 20" fill="currentColor">
                                    <path
                                        fillRule="evenodd"
                                        d="M17.707 5.293a1 1 0 010 1.414l-10 10a1 1 0 01-1.414 0l-5-5a1 1 0 111.414-1.414L7 14.586l9.293-9.293a1 1 0 011.414 0z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            ) : statusState.isError ? (
                                <svg className="h-3 w-3 mr-2" viewBox="0 0 20 20" fill="currentColor">
                                    <path
                                        fillRule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm0-2a6 6 0 100-12 6 6 0 000 12zm0-9a1 1 0 00-1 1v3a1 1 0 102 0V8a1 1 0 00-1-1zm0 6a1 1 0 100 2 1 1 0 000-2z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            ) : null}

                            {statusState.isUpdating
                                ? "Updating.."
                                : statusState.isSuccess
                                    ? statusState.updateMessage
                                    : statusState.isError
                                        ? statusState.updateMessage
                                        : "Enable"}
                        </button>
                    ) : (
                        <button style={{ minWidth: "120px" }} className={`capitalize flex items-center justify-center px-4 py-2 border border-transparent text-xs font-medium rounded-lg text-white ${original.fulfillment_status ? 'bg-purple-400' : 'bg-green-400'} hover:bg-purple-700`}>
                            {original.fulfillment_status ? original.fulfillment_status : 'Enabled'}
                        </button>
                    )}
                </div>
            );
        }
    },
    // {
    //     Header: "Actions",
    //     accessor: "id", // Assuming 'id' is a unique identifier for each product
    //     Cell: ({ cell }) => (
    //         <button onClick={() => handleViewAction(cell.row.original)}>
    //             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill='#7c3aed'><path d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z" /></svg>
    //         </button >
    //     ),
    // },

];

const perPages = [
    {
        "label": "50 per page",
        value: 50
    },
    {
        "label": "100 per page",
        value: 100
    },
    {
        "label": "250 per page",
        value: 250
    },
    {
        "label": "500 per page",
        value: 500
    }
];

const manufacturerFilterFields = [
    {
        labelText: "Manufacturer",
        labelFor: "manufacturer",
        id: "manufacturer",
        name: "manufacturer",
        placeholder: "Manufacturer",
        type: "select",
        options: [
            { value: "all", label: "All" },
            { value: "active", label: "Active" },
            { value: "draft", label: "Draft" },
            { value: "archived", label: "Archived" },
        ],
    },
    {
        id: "autoImport",
        name: "autoImport",
        type: "select",
        labelText: "Auto Import",
        labelFor: "autoImport",
        placeholder: "Select status",
        isRequired: false,
        options: [
            { value: "true", label: "Auto Import" },
            { value: "false", label: "Not Auto Import" },
        ],
    },
]


export { manufacturerFields, manufacturerFilterFields, perPages }