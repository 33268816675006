import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import Loader from '../../contexts/Loader';
import Breadcrumb from "../../contexts/Breadcrumb";
import { addProductFields, addProductOptionsFields, addProductVariantsFields } from "../../utils/addProductFields";
import Input from "../../contexts/Input";
import StatusPill from '../../components/datalist/StatusPill';
import FormAction from "../../contexts/FormAction";
import { ProductImageUpload } from "../../contexts/ProductImageUpload";
import CGMDescription from "../../components/quill/CGMDescription";
import { addShopifyProduct } from "../../utils/addShopifyProduct";

// const AddProduct = () => {
//     const [showLoader, setShowLoader] = useState(true);
//     const [productImages, setProductImages] = useState([]);
//     const [richText, setRichText] = useState('');
//     const [showOption, setShowOption] = useState(false);
//     const [showVariant, setShowVariant] = useState(false);
//     const requiredFields = ['productName', 'vendorname', 'producttype', 'sku', 'minimumQty', 'quantity', 'price', 'status'];
//     const optionAddrequiredFields = ['productName', 'vendorname', 'producttype', 'status'];

//     const navigate = useNavigate();

//     const fields = addProductFields;
//     let fieldsState = {};
//     fields.forEach(field => fieldsState[field.id] = '');

//     const optionFields = addProductOptionsFields;
//     let optionFieldsState = {};
//     optionFields.forEach(field => optionFieldsState[field.id] = '');

//     const variantsFields = addProductVariantsFields;
//     let variantsFieldsState = {};
//     variantsFields.forEach(field => variantsFieldsState[field.id] = '');

//     const [addproduct, setAddProduct] = useState(fieldsState);
//     const [addOption, setAddOption] = useState(optionFieldsState);
//     const [addVariants, setAddVariants] = useState(variantsFieldsState);
//     const [optionsArray, setOptionsArray] = useState([]);
//     const [variantsArray, setVariantsArray] = useState([]);
//     const [variantName, setVariantName] = useState('')
//     const [optionError, setOptionError] = useState(null);
//     const [error, setError] = useState(null);
//     const [variantError, setVariantError] = useState(null)

//     const handleChange = (e) => {
//         setAddProduct({ ...addproduct, [e.target.id]: e.target.value });
//         setAddOption({ ...addOption, [e.target.id]: e.target.value });
//         setAddVariants({ ...addVariants, [e.target.id]: e.target.value })
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         const action = e.nativeEvent.submitter.innerHTML.toLowerCase();
//         if (action.includes('cancel')) {
//             navigate(-1, { replace: true });
//         } else {
//             if (optionsArray.length > 0) {
//                 for (const field of optionAddrequiredFields) {
//                     if (!addproduct[field]) {
//                         return setError(`${field.replace('_', ' ')} field is required.`);
//                     }
//                 }
//             }
//             else {
//                 for (const field of requiredFields) {
//                     if (!addproduct[field]) {
//                         return setError(`${field.replace('_', ' ')} field is required.`);
//                     }
//                 }
//             }
//             if (!richText) {
//                 return setError(`Please input the description field.`);
//             }
//             if (productImages && productImages.length === 0) {
//                 return setError(`Please select at least one image.`);
//             }
//             handleImageUpload(productImages);
//             console.log(optionsArray, variantsArray)
//             addShopifyProduct(addproduct, productImages, richText, optionsArray, variantsArray);
//         }
//     };

//     const handleImageUpload = (uploadedImages) => {
//         setProductImages(uploadedImages);
//     };

//     const handleRemoveImage = (index) => {
//         const newImages = [...productImages];
//         newImages.splice(index, 1);
//         setProductImages(newImages);
//     }

//     const handleTextChange = (value) => {
//         setRichText(value);
//     };

//     const handleOptions = () => {
//         setShowOption(true);
//     };

//     const handleOptionAdd = (e) => {
//         e.preventDefault();
//         if (!addOption.options || !addOption.optionsValue) {
//             setOptionError(`${!addOption.options ? "Select Options" : "Input Option Value"} field is required`);
//             return;
//         }
//         if (optionsArray > 2) {
//             return setOptionError(`You have only three option to add`);
//         }
//         const newOption = {
//             options: addOption.options,
//             optionsValue: addOption.optionsValue
//         };
//         setOptionsArray((prevOptions) => [...prevOptions, newOption]);
//         setAddOption(optionFieldsState);
//         setOptionError(null);
//         setShowVariant(true);
//         setVariantName(addOption.optionsValue);
//     };

//     const handleRemoveOption = (index) => {
//         setOptionsArray((prevOptions) => {
//             const updatedOptions = [...prevOptions];
//             updatedOptions.splice(index, 1);
//             return updatedOptions;
//         });
//     };


//     const handleVariantsAdd = (e) => {
//         e.preventDefault();
//         const variantRequiredFields = ['variantprice', 'variantquantity', 'variantsku']
//         for (const field of variantRequiredFields) {
//             if (!addVariants[field]) {
//                 return setVariantError(`${field.replace('_', ' ')} field is required.`);
//             }
//         }

//         const newVariant = {
//             variantname: variantName,
//             variantprice: addVariants.variantprice,
//             variantquantity: addVariants.variantquantity,
//             variantsku: addVariants.variantsku
//         };
//         setVariantsArray((prevVariants) => [...prevVariants, newVariant]);
//         setAddVariants(variantsFieldsState);
//         setVariantName('');
//     };


//     const handleRemoveVariants = (index) => {
//         setVariantsArray((prevVariants) => {
//             const updatedVariants = [...prevVariants];
//             updatedVariants.splice(index, 1);
//             return updatedVariants;
//         });
//     }

//     return (
//         <>
//             {showLoader ?
//                 (<Loader setShowLoader={setShowLoader} />) :
//                 (<>
//                     <div className='border-b pb-6'>
//                         <div className="flex flex-col items-start justify-between pb-1 space-y-4 lg:items-center lg:space-y-0 lg:flex-row">
//                             <h1 className="text-2xl font-semibold whitespace-nowrap">Product Add</h1>
//                             <Breadcrumb />
//                         </div>
//                         <p className="text-xs pb-4 text-gray-800">Create New product</p>
//                     </div>
//                     <div className="rounded-md border p-4 m-2 bg-gray-50 mt-4">
//                         <form className="space-y-6 p-3" onSubmit={handleSubmit}>
//                             <div className="flex flex-wrap -mx-2 overflow-y-auto hover:overflow-y-auto">
//                                 {fields.map((field) => (
//                                     <div className="w-full lg:w-1/4 px-2" key={field.id}>
//                                         <label className="block -mb-2">
//                                             <span className="text-md font-medium text-slate-700 mb-0">{field.placeholder}</span>
//                                         </label>
//                                         <Input
//                                             key={field.id}
//                                             handleChange={handleChange}
//                                             value={addproduct[field.id]}
//                                             labelText={field.labelText || field.placeholder}
//                                             labelFor={field.labelFor || field.placeholder}
//                                             id={field.id}
//                                             name={field.name}
//                                             type={field.type}
//                                             placeholder={field.placeholder}
//                                             options={field.options}
//                                             pattern={field.pattern}
//                                             inputMode={field.inputMode}
//                                         />
//                                     </div>
//                                 ))}
//                             </div>

//                             <div className="w-full">
//                                 <label className="block mb-2">
//                                     <span className="text-md font-medium text-slate-700 mb-0">Variants</span>
//                                 </label>
//                                 <button onClick={handleOptions}>Add options like size or color</button>
//                             </div>
//                             {showOption ? (
//                                 <>
//                                     <div className="flex flex-wrap -mx-2 overflow-y-auto hover:overflow-y-auto">
//                                         {optionFields.map((field) => (
//                                             <div className="w-full lg:w-1/2 px-2" key={field.id}>
//                                                 <label className="block -mb-2">
//                                                     <span className="text-md font-medium text-slate-700 mb-0">{field.placeholder}</span>
//                                                 </label>
//                                                 <Input
//                                                     key={field.id}
//                                                     handleChange={handleChange}
//                                                     value={addOption[field.id]}
//                                                     labelText={field.labelText || field.placeholder}
//                                                     labelFor={field.labelFor || field.placeholder}
//                                                     id={field.id}
//                                                     name={field.name}
//                                                     type={field.type}
//                                                     placeholder={field.placeholder}
//                                                     options={field.options}
//                                                     pattern={field.pattern}
//                                                     inputMode={field.inputMode}
//                                                 />
//                                             </div>
//                                         ))}
//                                         <button onClick={handleOptionAdd} className="mx-2 group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">Add Options</button>
//                                     </div>
//                                     {optionError && (
//                                         <div className="text-red-500">{optionError}</div>
//                                     )}
//                                 </>
//                             ) : null}



//                             {optionsArray.length > 0 ? (
//                                 <div className="w-full">
//                                     <h2 className="text-lg font-semibold">Variants:</h2>
//                                     {optionsArray.map((variant, index) => (
//                                         <div className="flex items-center justify-between mt-2" key={index}>
//                                             <div className="flex space-x-4" >
//                                                 {Object.entries(variant).map(([key, value]) => (
//                                                     <div key={key}>
//                                                         <span className="text-sm text-gray-700">{key}</span>
//                                                         <span className="text-gray-900 font-semibold">{value}</span>
//                                                     </div>
//                                                 ))}
//                                             </div>
//                                             <button
//                                                 onClick={() => handleRemoveOption(index)}
//                                                 className="text-red-600 hover:text-red-800"
//                                             >
//                                                 Remove
//                                             </button>
//                                         </div>
//                                     ))}
//                                 </div>
//                             ) : null}




//                             {showVariant ? (
//                                 <div className="w-full my-2 p-1 bg-gray-200">
//                                     <div className="flex flex-wrap -mx-2 overflow-y-auto hover:overflow-y-auto">
//                                         {variantsFields.map((field) => (
//                                             <div className="w-full lg:w-1/2 px-2" key={field.id}>
//                                                 <label className="block -mb-2">
//                                                     <span className="text-md font-medium text-slate-700 mb-0">{field.placeholder}</span>
//                                                 </label>
//                                                 <Input
//                                                     key={field.id}
//                                                     handleChange={handleChange}
//                                                     value={field.id == 'variantname' ? variantName : addVariants[field.id]}
//                                                     labelText={field.labelText || field.placeholder}
//                                                     labelFor={field.labelFor || field.placeholder}
//                                                     id={field.id}
//                                                     name={field.name}
//                                                     type={field.type}
//                                                     placeholder={field.placeholder}
//                                                     options={field.options}
//                                                     pattern={field.pattern}
//                                                     inputMode={field.inputMode}
//                                                     isReadonly={field.isReadonly}
//                                                 />
//                                             </div>
//                                         ))}
//                                         <button onClick={handleVariantsAdd} className="mx-2 group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">Add Variants</button>
//                                     </div>
//                                     {variantError && (
//                                         <div className="text-red-500">{variantError}</div>
//                                     )}
//                                 </div>
//                             ) : null}

//                             {variantsArray.length > 0 ? (
//                                 <div className="w-full">
//                                     <h2 className="text-lg font-semibold">Variants:</h2>
//                                     {variantsArray.map((variant, index) => (
//                                         <div className="flex items-center justify-between mt-2" key={index}>
//                                             <div className="flex space-x-4">
//                                                 {Object.entries(variant).map(([key, value]) => (
//                                                     <div key={key}>
//                                                         <span className="text-sm text-gray-700">{key}</span>
//                                                         <span className="text-gray-900 font-semibold">{value}</span>
//                                                     </div>
//                                                 ))}
//                                             </div>
//                                             <button
//                                                 onClick={() => handleRemoveVariants(index)}
//                                                 className="text-red-600 hover:text-red-800"
//                                             >
//                                                 Remove
//                                             </button>
//                                         </div>
//                                     ))}
//                                 </div>
//                             ) : null}


//                             <div className="w-full">
//                                 <label className="block mb-2">
//                                     <span className="text-md font-medium text-slate-700 mb-0">Product Description</span>
//                                 </label>
//                                 <CGMDescription value={richText} onChange={handleTextChange} readOnly={false} />
//                             </div>
//                             <ProductImageUpload onUpload={handleImageUpload} />
//                             {error &&
//                                 <div className="bg-red-100 border border-red-200 text-red-700 px-2 py-2 rounded relative" role="alert">
//                                     <strong className="font-bold text-sm">Holy Shit ! </strong>
//                                     <span className="block sm:inline text-xs">{error}</span>
//                                 </div>
//                             }
//                             <div className="flex gap-4 w-32">
//                                 <FormAction text="Save" data-action="save" />
//                                 <FormAction text="Cancel" classes="bg-red-500 hover:bg-red-700" data-action="cancel" />
//                             </div>
//                         </form>
//                     </div >
//                 </>)
//             }
//         </>
//     );
// };



const AddProduct = () => {
    const [options, setOptions] = useState([]);
    const [variants, setVariants] = useState([]);
    const [newOptionName, setNewOptionName] = useState('');
    const [variantData, setVariantData] = useState({
        variantName: '',
        price: '',
        sku: '',
        available: '',
    });
    // const [variantData, setVariantData] = useState({ variants: [] });


    const optionFields = ['Size', 'Color', 'Fabric']; // Example option fields
    const maxOptions = 3; // Maximum allowed options

    const addOption = () => {
        if (options.length < maxOptions) {
            setOptions([...options, { name: '', values: [] }]);
        }
    };

    const removeOption = (optionIndex) => {
        const updatedOptions = [...options];
        updatedOptions.splice(optionIndex, 1);
        setOptions(updatedOptions);
    };

    const addValue = (optionIndex) => {
        const updatedOptions = [...options];
        updatedOptions[optionIndex].values.push('');
        setOptions(updatedOptions);
    };

    const removeValue = (optionIndex, valueIndex) => {
        const updatedOptions = [...options];
        updatedOptions[optionIndex].values.splice(valueIndex, 1);
        setOptions(updatedOptions);
    };

    const handleChangeOption = (optionIndex, value) => {
        const updatedOptions = [...options];
        updatedOptions[optionIndex].name = value;
        setOptions(updatedOptions);
    };

    const handleChangeValue = (optionIndex, valueIndex, value) => {
        const updatedOptions = [...options];
        updatedOptions[optionIndex].values[valueIndex] = value;
        setOptions(updatedOptions);
    };

    const createVariants = () => {
        if (options.some((option) => option.name === '' || option.values.length === 0)) {
            console.log('Please fill in all option fields and values.');
            return;
        }

        const newVariants = [];

        const optionCount = options.length;
        const optionIndexes = Array(optionCount).fill(0);
        const optionMaxIndexes = options.map((option) => option.values.length - 1);

        while (true) {
            const variantOptions = optionIndexes.map((optionIndex, index) => ({
                [`option${index}`]: options[index].name,
                optionvalue: options[index].values[optionIndex],
            }));

            newVariants.push(variantOptions);

            let i = optionCount - 1;
            while (i >= 0 && optionIndexes[i] === optionMaxIndexes[i]) {
                optionIndexes[i] = 0;
                i--;
            }

            if (i < 0) {
                break;
            }

            optionIndexes[i]++;
        }

        setVariants(newVariants);
    };

    const handleVariantDataChange = (variantIndex, field, value) => {
        setVariantData((prevState) => {
            const updatedVariants = [...prevState.variants];
            updatedVariants[variantIndex] = {
                ...updatedVariants[variantIndex],
                [field]: value,
            };
            return {
                ...prevState,
                variants: updatedVariants,
            };
        });
    };




    // useEffect(() => {
    //     const variantDataOptions = variants.map((variant) =>
    //         variant.map((option) => option.optionvalue)
    //     );
    //     setVariantData((prevState) => ({
    //         ...prevState,
    //         options: variantDataOptions,
    //         data: [...variants],
    //     }));
    // }, [variants]);






    return (
        <div>
            <h2>Add Product</h2>

            {options.length < maxOptions && (
                <div className="mb-4">
                    <div className="flex items-center mb-2">
                        <input
                            type="text"
                            placeholder="Option name"
                            value={newOptionName}
                            onChange={(e) => setNewOptionName(e.target.value)}
                            className="mr-2 px-2 py-1 border rounded"
                        />
                        <button
                            onClick={() => {
                                if (newOptionName !== '') {
                                    setOptions([...options, { name: newOptionName, values: [] }]);
                                    setNewOptionName('');
                                }
                            }}
                            className="px-2 py-1 border rounded bg-blue-500 text-white"
                        >
                            Done
                        </button>
                    </div>
                </div>
            )}

            {options.map((option, optionIndex) => (
                <div key={optionIndex} className="mb-4">
                    <div className="flex items-center mb-2">
                        <input
                            type="text"
                            value={option.name}
                            placeholder="Option name"
                            onChange={(e) => handleChangeOption(optionIndex, e.target.value)}
                            className="mr-2 px-2 py-1 border rounded"
                        />
                        <button
                            onClick={() => removeOption(optionIndex)}
                            className="px-2 py-1 border rounded bg-red-500 text-white"
                        >
                            Remove
                        </button>
                    </div>
                    {option.values.map((value, valueIndex) => (
                        <div key={valueIndex} className={`flex items-center mb-2`}>
                            <input
                                type="text"
                                value={value}
                                placeholder="Option value"
                                onChange={(e) => handleChangeValue(optionIndex, valueIndex, e.target.value)}
                                className="mr-2 px-2 py-1 border rounded"
                            />
                            <button
                                onClick={() => removeValue(optionIndex, valueIndex)}
                                className="px-2 py-1 border rounded bg-red-500 text-white"
                            >
                                Remove
                            </button>
                        </div>
                    ))}
                    <button
                        onClick={() => addValue(optionIndex)}
                        className="px-2 py-1 border rounded bg-blue-500 text-white"
                    >
                        Add Value
                    </button>
                </div>
            ))}

            {options.length === maxOptions && (
                <div className="mb-4">
                    <p>Maximum number of options reached.</p>
                </div>
            )}

            <button
                onClick={createVariants}
                className={`px-2 py-1 border rounded bg-blue-500 text-white ${options.some((option) => option.name === '' || option.values.length === 0)
                    ? 'opacity-50 cursor-not-allowed'
                    : ''
                    }`}
                disabled={options.some((option) => option.name === '' || option.values.length === 0)}
            >
                Create Variants
            </button>

            {variants.length > 0 && (
                <div>
                    <h3>Variants</h3>
                    {variants.map((variant, variantIndex) => (
                        <div key={variantIndex} className="mb-4">
                            {variant.map((option, index) => (
                                <span key={`option${index}`} className="badge mr-2">
                                    {`option${index}`}: {option.optionvalue}
                                </span>
                            ))}
                            <input
                                type="text"
                                placeholder="Variant Name"
                                className="mr-2 px-2 py-1 border rounded"
                                value={variantData[variantIndex]?.variantName || ''}
                                onChange={(e) =>
                                    handleVariantDataChange(variantIndex, 'variantName', e.target.value)
                                }
                            />
                            <input
                                type="text"
                                placeholder="Price"
                                className="mr-2 px-2 py-1 border rounded"
                                value={variantData[variantIndex]?.price || ''}
                                onChange={(e) =>
                                    handleVariantDataChange(variantIndex, 'price', e.target.value)
                                }
                            />
                            <input
                                type="text"
                                placeholder="SKU"
                                className="mr-2 px-2 py-1 border rounded"
                                value={variantData[variantIndex]?.sku || ''}
                                onChange={(e) =>
                                    handleVariantDataChange(variantIndex, 'sku', e.target.value)
                                }
                            />
                            <input
                                type="text"
                                placeholder="Available"
                                className="mr-2 px-2 py-1 border rounded"
                                value={variantData[variantIndex]?.available || ''}
                                onChange={(e) =>
                                    handleVariantDataChange(variantIndex, 'available', e.target.value)
                                }
                            />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default AddProduct;
