import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { PRODUCT_API_ENDPOINTS, API_HEADERS, HANDLE_API_ERROR } from '../../utils/config';
import CGMDescription from '../../components/quill/CGMDescription';
import Loader from '../../contexts/Loader';
import classNames from "classnames";
import StatusPill from '../../components/datalist/StatusPill';
import Table from '../../components/datalist/Table';

const ProductDetail = () => {
  const { productId } = useParams();
  const [data, setData] = useState({});
  const [showLoader, setShowLoader] = useState(true);
  const [selectedVariant, setSelectedVariant] = useState({});

  const variantsDataFileds = [
    {
      Header: "Option 1",
      accessor: "option1",
    },
    {
      Header: "Option 2",
      accessor: "option2",
    },
    {
      Header: "Option 3",
      accessor: "option3",
    },
    {
      Header: "Title",
      accessor: "title",
    },
    {
      Header: "Price",
      accessor: "price",
    },
    {
      Header: "SKU",
      accessor: "sku",
    },
    {
      Header: "Quantity",
      accessor: "inventory_quantity",
    }
  ]

  useEffect(() => {
    productDetails();
  }, [productId]);

  const productDetails = async () => {
    let requestHeaders = API_HEADERS();
    try {
      const response = await axios.get(`${PRODUCT_API_ENDPOINTS.GET_PRODUCT_DETAILS}${productId}`, requestHeaders);
      const preparedData = await dataPrepare(response.data.product.shopify_data);
      setData(preparedData);
    } catch (error) {
      HANDLE_API_ERROR(error);
    }
  };

  const dataPrepare = async (data) => {
    const imagePrepare = data.images.map((image, i) => (
      <img
        key={image.id}
        src={image.src}
        alt="Product Image"
        className="product-images w-full mt-1 object-cover"
      />
    ));

    const optionPrepare = data.options.map((option, i) => {
      const optionValues = option.values.map((value) => (
        <option key={value} value={value}>
          {value}
        </option>
      ));
      return (
        <div key={option.id} className='mb-4'>
          <label htmlFor={option.name} className='capitalize '>{option.name}</label>
          <select
            id={option.name}
            value={selectedVariant[option.name]}
            onChange={(e) =>
              setSelectedVariant((prev) => ({
                ...prev,
                [option.name]: e.target.value,
              }))
            }
            className="block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
          >
            {optionValues}
          </select>
        </div>
      );
    });

    const variantPrepare = data.variants
      .filter((variant) =>
        Object.keys(selectedVariant).every(
          (option) => selectedVariant[option] === variant[option]
        )
      )
      .map((variant, i) => variant);




    return {
      vendor: data.vendor,
      title: data.title,
      descripition: data.body_html,
      approval: data.approved,
      images: imagePrepare,
      variants: variantPrepare,
      options: optionPrepare,
      tags: [data.tags],
      product_type: data.product_type,
      status: data.status,
    };
  };

  return (

    <>
      {
        showLoader ?
          (<Loader setShowLoader={setShowLoader} />) :
          (<div>
            <div className="flex flex-col items-start justify-between pb-6 space-y-4 border-b lg:items-center lg:space-y-0 lg:flex-row">
              <h1 className="text-2xl font-semibold whitespace-nowrap">Products Details</h1>
              <p>Product Id: {productId}</p>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full md:w-1/2 lg:w-1/3 p-4">
                {data.images ? (
                  <div className="flex flex-wrap">{data.images}</div>
                ) :
                  (
                    <div className="flex flex-col items-center bg-slate-300 h-48 p-4 justify-center">
                      <h2 className="text-white text-top text-center text-gray-800">No Image</h2>
                    </div>)
                }
              </div>
              <div className="w-full md:w-1/2 lg:w-2/3">
                <div className='bg-gray-50 p-4 mt-5 relative'>
                  {/* <div className={`text-1xl mb-2 absolute px-4 py-2 font-bold top-0 left-0 mb-4 w-full text-center ${data.approval ? "shadow-sm bg-blue-100 text-blue-700" : "shadow-sm bg-pink-100 text-pink-700"}`}>
                    {data.approval ? "Product Approved By Admin" : "Product Not Approved By Admin"}
                  </div> */}

                  <h2 className="text-1xl mb-2">Vendor: {data.vendor ? (data.vendor) : "No Vendor"}</h2>
                  <h1 className="text-2xl font-bold mb-4">{data.title ? (data.title) : "No Title"}</h1>
                  <div className='text-1xl mb-4'>Product type : <span className='capitalize '>{data.product_type ? (data.product_type) : "No Type"}</span></div>
                  <div className='text-1xl mb-4'>Status :  {data.status ? <StatusPill value={data.status} /> : " No Status"} </div>
                  <div className='mb-4'><CGMDescription toolbar={false} value={`Product Descripition :${data.descripition ? (data.descripition) : "No Descripition"}`} /></div>
                  <div className="text-1xl mb-4 pr-3">Tag :
                    {data.tags && data.tags.length > 0 ? (
                      data.tags.map((value) => {
                        const updateTags = value.split(",");
                        return updateTags.map((tag) => (
                          <span key={tag} className="ml-2 inline-block px-3 py-1 text-xs font-semibold text-white bg-gray-400 rounded-full">{tag}</span>
                        ));
                      })
                    ) : "No tags"}
                  </div>


                  {data.options && (
                    <div>{data.options}</div>
                  )}


                  <div>
                    {data.variants && data.variants.length > 0 ? (
                      <>
                        <Table columns={variantsDataFileds} data={data.variants} dimension={{ width: '', maxHeight: '300px' }}></Table>
                      </>
                    ) : (
                      <p>No variants available.</p>
                    )}
                  </div>

                </div>
              </div>
            </div>
          </div >)
      }


    </>
  );


}


export default ProductDetail;